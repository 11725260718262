import {apiUrl} from '../utils/constants';
import {GET, POST, PUT, DELETE} from './api.service.wrapper';

export const GetAvatars = async (page = '', perPage = '', keyword = '') => {
    let params = {};

    if (page) {
        params['page'] = page;
    }

    if (perPage) {
        params['per-page'] = perPage;
    }

    if (keyword) {
        params['keyword'] = keyword;
    }

    return await GET(apiUrl.avatars, params);
}

export const GetAvatarById = async (id) => {
    return  await GET(apiUrl.avatars + '/' + id);
}

export const CreateAvatar = async (data) => {
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }
    return await POST(apiUrl.avatars, data, config);
}

export const UpdateAvatar = async (id, data) => {
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }
    return await PUT(apiUrl.avatars, id, data, config);
}

export const DeleteAvatar = async (id) => {
    return await DELETE(apiUrl.avatars, id);
}

export const DeleteAllAvatars = async (ids) => {
    return await POST(apiUrl.delete_all_avatars, ids);
}
