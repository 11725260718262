import {apiUrl} from '../utils/constants';
import {GET, POST, PUT, DELETE} from './api.service.wrapper';

export const GetPages = async (page = '', perPage = '') => {
    let params = {};

    if(page){
        params['page'] = page;
    }

    if(perPage){
        params['per-page'] = perPage;
    }
    const response = await GET(apiUrl.pages, params);
    return response;
}

export const GetPageById = async (id) => {
    let response = '';
    response = await GET(apiUrl.pages + '/' + id);
    return response;
}

export const CreatePage = async (data) => {
    const response = await POST(apiUrl.pages, data);
    return response;
}

export const UpdatePage = async (id, data) => {
    const response = await PUT(apiUrl.pages, id, data);
    return response;
}

export const DeletePage = async (id) => {
    const response = await DELETE(apiUrl.pages, id);
    return response;
}

