import React, {useEffect, useState} from 'react'
import {Row, Col, Button, Dropdown} from 'react-bootstrap';
import {FaChartPie} from "react-icons/fa";
import DashboardStat from '../../components/DashboardStat'
import {GetDashboard} from "../../services/admin/dashboard.service"
import {toast} from "react-toastify";
import {NavLink} from "react-router-dom"
import {constants} from "../../utils/constants";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import {useHistory} from 'react-router-dom';
import Profile01 from '../../assets/images/profile.jpg'
import {ChevronRight} from "../../components/SvgIcons";

const InitialStats = {
    orders_count: '',
    confirmed_orders_count: '',
    cancelled_orders_count: '',
    paid_orders_count: '',
    shipped_orders_count: '',
    completed_orders_count: '',
    reviewed_orders_count: '',
    returned_orders_count: '',
    products_count: '',
    csa_agents_count: '',
    vendors_count: '',
    ads_count: '',
}

function Analytics() {
    const history = useHistory();
    const [stats, setStats] = useState(InitialStats);
    // useEffect(async () => {
    //     let params = {role_id: RoleId}
    //     await GetDashboard(params).then((data) => {
    //         if (data.status) {
    //             setStats({
    //                 orders_count: data.data.orders_count,
    //                 confirmed_orders_count: data.data.confirmed_orders_count,
    //                 cancelled_orders_count: data.data.cancelled_orders_count,
    //                 paid_orders_count: data.data.paid_orders_count,
    //                 shipped_orders_count: data.data.shipped_orders_count,
    //                 completed_orders_count: data.data.completed_orders_count,
    //                 reviewed_orders_count: data.data.reviewed_orders_count,
    //                 returned_orders_count: data.data.returned_orders_count,
    //                 products_count: data.data.products_count,
    //                 csa_agents_count: data.data.csa_agents_count,
    //                 vendors_count: data.data.vendors_count,
    //                 ads_count: data.data.ads_count,
    //             })
    //         } else {
    //             toast.error(data.message);
    //         }
    //     }).catch((error) => {
    //         if (error.response.status == 401) {
    //             EmptyLocalStorage()
    //             history.push('/');
    //         } else {
    //             return toast.error(error.response.data.message);
    //         }
    //     })
    // }, [])

    return (
        <div>
            <Row className={"mb-4"}>
                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                    <h1>Analytics</h1>
                </Col>
                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                    <div className={"timelineControls"}>
                        <p>Interval</p>
                        <Dropdown className={"intervalDropdown"}>
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                Current month
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">This week</Dropdown.Item>
                                <Dropdown.Item href="#/action-2">Today</Dropdown.Item>
                                <Dropdown.Item href="#/action-3">Last month</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>

                </Col>
            </Row>
            <Row className={"mb-4"}>
                <Col xs={12} sm={12} md={6} lg={3} xl={3}>
                    <NavLink to={"#"} className={"linkStats"}>
                        <DashboardStat title={"Messages sent"} countMain={stats.orders_count ? stats.orders_count : "2,890"}/>
                    </NavLink>
                </Col>
                <Col xs={12} sm={12} md={6} lg={3} xl={3}>
                    <NavLink to={"#"} className={"linkStats"}>
                        <DashboardStat title={"Purchases"} countMain={stats.orders_count ? stats.orders_count : "$1,290"}/>
                    </NavLink>
                </Col>
                <Col xs={12} sm={12} md={6} lg={3} xl={3}>
                    <NavLink to={"#"} className={"linkStats"}>
                        <DashboardStat title={"Payouts"} countMain={stats.orders_count ? stats.orders_count : "$890"}/>
                    </NavLink>
                </Col>
                <Col xs={12} sm={12} md={6} lg={3} xl={3}>
                    <NavLink to={"#"} className={"linkStats"}>
                        <DashboardStat title={"Accounts"} userCount={"890"} proCount={"28"}/>
                    </NavLink>
                </Col>
            </Row>
        </div>
    )
}

export default Analytics
