import React, {useEffect, useState} from 'react'
import {AddIconOutline, MenuDots, SearchIcon} from "../../components/SvgIcons";
import {Button, Col, Dropdown, DropdownButton, Form, FormControl, InputGroup, Row} from "react-bootstrap"
import {useHistory} from "react-router-dom";
import swal from "sweetalert";
import ThemeModal from "../../components/ThemeModal";
import {useForm} from "react-hook-form";
import {CreateFaq, DeleteAllFaqs, DeleteFaq, GetFaqs, UpdateFaq} from "../../services/faqs.service";
import {toast} from "react-toastify";
import {constants, customStyles} from "../../utils/constants";
import DataTable from 'react-data-table-component';
import BarLoader from "react-spinners/BarLoader";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import {FaEdit, FaTrash} from "react-icons/fa";
import {DeleteUser} from "../../services/users.service";

function Faq() {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(constants.PERPAGE);
    const [rows, setRows] = useState();
    const [keyword, setKeyword] = useState('');
    const [modalShow, setModalShow] = useState(false);
    const [editModalShow, setEditModalShow] = useState(false);
    const [editModalData, setEditModalData] = useState([]);
    const [viewModalShow, setViewModalShow] = useState(false);
    const [viewModalData, setViewModalData] = useState([]);
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [selectedIds, setSelectedIds] = useState([]);
    const {register, handleSubmit, reset, setValue, formState: {errors}} = useForm({
        mode: "onChange"
    });

    useEffect(() => {
        getFaqs()
    }, [page, perPage, keyword])

    useEffect(async () => {
        if (!modalShow && !editModalShow) {
            // setFileData(false)
            setEditModalData([])
            reset();
        }
    }, [modalShow, editModalShow])

    const getFaqs = async () => {
        setLoading(true)
        await GetFaqs(page, perPage, keyword).then((result) => {
            if (result.status) {
                if (result.data) {
                    const rowData = [];
                    // setPage(result.data.meta.current_page)
                    // setTotalRows(result.data.meta.total);
                    result.data.map((dt, index) => {
                        rowData.push({
                            Id: dt.id,
                            Index: ++index,
                            question: dt.question.length > 100 ? (dt.question.slice(0, 100) + '...') : dt.question,
                            answer: dt.answer.length > 100 ? (dt.answer.slice(0, 100) + '...') : dt.answer,
                        });
                    })

                    setRows(rowData);
                    setLoading(false)
                }
            } else {
                setLoading(false)
                return toast.error(result.message);
            }
        }).catch((error) => {
            setLoading(false)
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })
    }

    const onDelete = (Id) => {
        swal({
            title: "Are you sure?",
            text: "Are you sure you want to delete the selected Faq?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willDelete) => {
            if (willDelete) {
                await DeleteFaq(Id).then((result) => {
                    if (result.status) {
                        swal(result.message, {
                            icon: "success",
                        });
                        getFaqs()
                    } else {
                        toast.error(result.message);
                    }
                }).catch((error) => {
                    if (error.response.status == 401) {
                        EmptyLocalStorage()
                        history.push('/');
                    } else {
                        return toast.error(error.response.data.message);
                    }
                })
            }
        });
    }

    const onView = (data) => {
        history.push('/profile-details/' + data.id, {
            data: data
        });
    }
    const userActions = (data) => {
        return (
            <DropdownButton align="end" className={"actionDropdown"} id="dropdown-item-button" title={<MenuDots/>}>
                {/*<Dropdown.Item as="button" onClick={() => {
                    setViewModalShow(true)
                    setViewModalData(data.Id)
                    setValue('id', data.Id);
                    setValue('question', data.question);
                    setValue('answer', data.answer);
                }}>View</Dropdown.Item>*/}
                <Dropdown.Item as="button" onClick={() => {
                    setEditModalShow(true)
                    setEditModalData(data.Id)
                    setValue('id', data.Id);
                    setValue('question', data.question);
                    setValue('answer', data.answer);
                }}>Edit</Dropdown.Item>
                <Dropdown.Item as="button" onClick={() => onDelete(data.Id)}>Delete</Dropdown.Item>
            </DropdownButton>
        )
    }
    const [disableButton, setDisableButton] = useState(false);
        // return (
        //     <DropdownButton align="end" className={"actionDropdown"} id="dropdown-item-button" title={<MenuDots/>}>
        //         <Dropdown.Item as="button" onClick={async () => {
        //             setEditModalShow(true)
        //             setEditModalData(data.Id)
        //             setValue('id', data.Id);
        //             setValue('question', data.question);
        //             setValue('answer', data.answer);
        //         }}>
        //             Edit
        //         </Dropdown.Item>
        //         <Dropdown.Item as="button" onClick={() => onDelete(data.Id)}>Delete</Dropdown.Item>
        //     </DropdownButton>
        // )

    const onDeleteAll = () => {
        swal({
            title: "Are you sure?",
            text: "Are you sure you want to delete the selected rows?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willDelete) => {
            if (willDelete) {
                await DeleteAllFaqs({ids: selectedIds}).then((result) => {
                    if (result.status) {
                        swal(result.message, {
                            icon: "success",
                        });
                        setSelectedIds([])
                        setIsCheckAll(false)
                        getFaqs()
                    } else {
                        toast.error(result.message);
                    }
                }).catch((error) => {
                    return toast.error(error.response.data.message);
                })
            }
        });
    }


    const columns = [
        /*{
            id: 'delete',
            name: '',
            maxWidth: '0%',
            selector: rowData => rowData.Index
        },*/
        {
            id: 'question',
            name: 'Question',
            selector: rowData => rowData.question,
        },
        {
            id: 'answer',
            name: 'Answer',
            selector: rowData => rowData.answer,
        },
        {
            id: 'action',
            name: 'Action',
            cell: rowData => userActions(rowData)
        },
    ];console.log(rows)

    const onModalSubmit = async (data) => {
        let formData = new FormData();
        formData.append('question', data.question);
        formData.append('answer', data.answer);

        await CreateFaq(formData).then((data) => {
            if (data.status) {
                toast.success(data.message);
                getFaqs();
                setModalShow(false)
                reset();
            } else {
                toast.error(data.message);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })
    };

    const onEditModalSubmit = async (data) => {
        let id = data.id
        let formData = new FormData();
        formData.append('question', data.question);
        formData.append('answer', data.answer);

        await UpdateFaq(id, formData).then((data) => {
            if (data.status) {
                toast.success(data.message);
                getFaqs()
                reset();
                setEditModalShow(false)
            } else {
                toast.error(data.message);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })
    };

    const handleChange = (event) => {
        // setFileData(URL.createObjectURL(event.target.files[0]));
    }

    /*
    *
    *  form elements inside popup
    *
    * */

    const ModalForm = () => {
        return (
            <div className={"modalBodyContent"}>
                <Form encType="multipart/form-data" onSubmit={handleSubmit(onModalSubmit)}>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Form.Group className="mb-3" controlId="question">
                                {/*En*/}
                                <Form.Label>Question<small style={{color: "red"}}>*</small></Form.Label>
                                <Form.Control
                                    placeholder="Type Question"
                                    id={"question"}
                                    name="question"
                                    autocomplete="off"
                                    {...register('question', {
                                        onChange: (e) => {
                                            setValue('question', e.target.value)
                                        },
                                        required: {
                                            value: "required",
                                            message: "Question is required"
                                        },
                                        maxLength: {
                                            value: 255,
                                            message: "Max length is 255"
                                        },
                                    })} type="text"
                                />
                                {errors.question && <Form.Text
                                    className="text-muted validationText hasError">{errors.question.message}</Form.Text>}
                            </Form.Group>
                        </Col>

                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Form.Group className="mb-3" controlId="answer">
                                {/*En*/}
                                <Form.Label>Answer<small style={{color: "red"}}>*</small></Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={10}
                                    placeholder="Type Answer"
                                    id={"answer"}
                                    name="answer"
                                    autocomplete="off"
                                    {...register('answer', {
                                        onChange: (e) => {
                                            setValue('answer', e.target.value)
                                        },
                                        required: {
                                            value: "required",
                                            message: "Answer is required"
                                        },
                                        maxLength: {
                                            value: 1000,
                                            message: "Max length is 1000"
                                        },
                                    })} type="text"
                                />
                                {errors.answer && <Form.Text
                                    className="text-muted validationText hasError">{errors.answer.message}</Form.Text>}
                            </Form.Group>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <input type={"submit"} className={"btn btn-green-theme w-100 mt-3"} value={"Submit"}/>
                        </Col>
                    </Row>
                </Form>
            </div>
        )
    }

    const EditModalForm = () => {
        return (
            <div className={"modalBodyContent"}>
                <Form className={"form-updateType"} onSubmit={handleSubmit(onEditModalSubmit)}>
                    <Form.Control
                        {...register('id')} type="hidden"/>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Form.Group className="mb-3" controlId="question">
                                <Form.Label>Question<small style={{color: "red"}}>*</small></Form.Label>
                                <Form.Control
                                    placeholder="Type Question"
                                    autocomplete="off"
                                    {...register('question', {
                                        onChange: (e) => {
                                            setValue('question', e.target.value)
                                        },
                                        required: {
                                            value: "required",
                                            message: "Question is required"
                                        },
                                        maxLength: {
                                            value: 255,
                                            message: "Max Length is 255"
                                        }
                                    })} type="text"
                                />
                                {errors.question && <Form.Text
                                    className="text-muted validationText hasError">{errors.question.message}</Form.Text>}
                            </Form.Group>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Form.Group className="mb-3" controlId="answer">
                                <Form.Label>Answer<small style={{color: "red"}}>*</small></Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={10}
                                    placeholder="Type Answer"
                                    autocomplete="off"
                                    {...register('answer', {
                                        onChange: (e) => {
                                            setValue('answer', e.target.value)
                                        },
                                        required: {
                                            value: "answer",
                                            message: "Answer is required"
                                        },
                                        minLength: {
                                            value: 3,
                                            message: "Min Length is 3"
                                        },
                                        maxLength: {
                                            value: 1000,
                                            message: "Max Length is 1000"
                                        }
                                    })} type="text"
                                />
                                {errors.answer && <Form.Text
                                    className="text-muted validationText hasError">{errors.answer.message}</Form.Text>}
                            </Form.Group>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <input type={"submit"} className={"btn btn-green-theme w-100 mt-3"} value={"Submit"}/>
                        </Col>
                    </Row>
                </Form>
            </div>
        )
    }

    const ViewModalForm = () => {
        return (
            <div className={"modalBodyContent"}>
                <Form className={"form-updateType"} onSubmit={handleSubmit(onEditModalSubmit)}>
                    <Form.Control
                        {...register('id')} type="hidden"/>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Form.Group className="mb-3" controlId="question">
                                <Form.Label>Question<small style={{color: "red"}}>*</small></Form.Label>
                                <Form.Control
                                    placeholder="Type Question"
                                    autocomplete="off"
                                    {...register('question', {
                                        onChange: (e) => {
                                            setValue('question', e.target.value)
                                        },
                                        required: {
                                            value: "required",
                                            message: "Question is required"
                                        },
                                        maxLength: {
                                            value: 255,
                                            message: "Max Length is 255"
                                        }
                                    })} type="text"
                                />
                                {errors.question && <Form.Text
                                    className="text-muted validationText hasError">{errors.question.message}</Form.Text>}
                            </Form.Group>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Form.Group className="mb-3" controlId="answer">
                                <Form.Label>Answer<small style={{color: "red"}}>*</small></Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={10}
                                    placeholder="Type Answer"
                                    autocomplete="off"
                                    {...register('answer', {
                                        onChange: (e) => {
                                            setValue('answer', e.target.value)
                                        },
                                        required: {
                                            value: "answer",
                                            message: "Answer is required"
                                        },
                                        minLength: {
                                            value: 3,
                                            message: "Min Length is 3"
                                        },
                                        maxLength: {
                                            value: 1000,
                                            message: "Max Length is 1000"
                                        }
                                    })} type="text"
                                />
                                {errors.answer && <Form.Text
                                    className="text-muted validationText hasError">{errors.answer.message}</Form.Text>}
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </div>
        )
    }

    return (
        <div className={"manageType"}>
            <h3 className="page-heading">Manage FAQs</h3>
            <hr/>
            <div className={"customOrderFilters"}>
                <Row>
                    <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                        <InputGroup className="mb-3 search-group">
                            <InputGroup.Text id="basic-addon1"><SearchIcon/></InputGroup.Text>
                            <FormControl
                                placeholder="Search"
                                aria-label="Search"
                                aria-describedby="Search"
                                maxLength={"255"}
                                onChange={(e) => {
                                    (e.target.value.length > 1) ? setKeyword(e.target.value) : setKeyword(null)
                                }}
                            />
                        </InputGroup>
                    </Col>

                    <Col xs={12} sm={12} md={7} lg={7} xl={7}>
                        <Row>
                            <Col xs={12} sm={12} md={3} lg={3} xl={3}></Col>
                            <Col xs={12} sm={12} md={5} lg={5} xl={5}></Col>
                            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                <Button className={"btn btn-green-theme h40 w-100"} onClick={() => setModalShow(true)}>
                                    <AddIconOutline/> Add New
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            <div className={"mt-3"}>
                <div className="theme-dataGrid products-dataGrid">
                    <DataTable
                        columns={columns}
                        data={rows}
                        progressPending={loading}
                        customStyles={customStyles}
                        selectableRows={false}
                        striped
                        highlightOnHover
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        paginationPerPage={perPage}
                        onChangePage={(page) => {
                            setPage(page);
                        }}
                        onChangeRowsPerPage={(currentRowsPerPage) => {
                            setPerPage(currentRowsPerPage)
                        }}
                        progressComponent={<BarLoader color={'#3D2570'} loading={loading} css={'marginTop: 10px'}
                                                      height={'2'} width={'100%'}/>}
                    />
                </div>
            </div>
            {/* Popup*/}
            <ThemeModal title={"Add New Faq"} size={"md"} content={<ModalForm/>} show={modalShow}
                        onHide={() => setModalShow(false)}/>
            <ThemeModal title={"Edit Faq"} size={"md"} content={<EditModalForm/>} show={editModalShow}
                        onHide={() => setEditModalShow(false)}/>
            <ThemeModal title={"View Faq"} size={"md"} content={<ViewModalForm/>} show={viewModalShow}
                        onHide={() => setViewModalShow(false)}/>
        </div>
    )
}

export default Faq
