import React, {useEffect, useState} from 'react'
import {AddIconOutline, MenuDots, SearchIcon} from "../../components/SvgIcons";
import {Button, Col, Dropdown, DropdownButton, Form, FormControl, InputGroup, Row} from "react-bootstrap"
import {useHistory} from "react-router-dom";
import swal from "sweetalert";
import ThemeModal from "../../components/ThemeModal";
import {useForm} from "react-hook-form";
import {CreateProfession, DeleteAllProfession, DeleteProfession, GetProfession, UpdateProfession} from "../../services/profession.service";
import {toast} from "react-toastify";
import {constants, customStyles} from "../../utils/constants";
import DataTable from 'react-data-table-component';
import BarLoader from "react-spinners/BarLoader";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import {FaEdit, FaTrash} from "react-icons/fa";
import {DeleteUser, GetUsers} from "../../services/users.service";

function Professions() {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(constants.PERPAGE);
    const [rows, setRows] = useState();
    const [keyword, setKeyword] = useState('');
    const [modalShow, setModalShow] = useState(false);
    const [editModalShow, setEditModalShow] = useState(false);
    const [editModalData, setEditModalData] = useState([]);
    const [viewModalShow, setViewModalShow] = useState(false);
    const [viewModalData, setViewModalData] = useState([]);
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [selectedIds, setSelectedIds] = useState([]);
    const [selectedName, setSelectedName] = useState('');

    const {register, handleSubmit, reset, setValue, formState: {errors}} = useForm({
        mode: "onChange"
    });
    const [disableButton, setDisableButton] = useState(false);

    useEffect(async () => {
        await getProfessions();
    }, [page, perPage, keyword])

    const getProfessions = async () => {
        setLoading(true)
        await GetProfession(page, perPage, keyword).then((result) => {
            if (result.status) {
                if (result.data) {
                    const rowData = [];
                    // setPage(result.data.meta.current_page)
                    // setTotalRows(result.data.meta.total);
                    result.data.map((dt, index) => {
                        rowData.push({
                            id: dt.id,
                            index: ++index,
                            name: dt.name
                        });
                    })
                    setRows(rowData);
                    setLoading(false)
                }
            } else {
                setLoading(false)
                return toast.error(result.message);
            }
        }).catch((error) => {
            setLoading(false)
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })
    }

    useEffect(async () => {
        if (!modalShow && !editModalShow) {
            // setFileData(false)
            setEditModalData([])
            reset();
        }
    }, [modalShow, editModalShow])
    const onProfessionDelete = (id) => {
        swal({
            title: "Are you sure?",
            text: "Are you sure you want to delete this Profession?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willShip) => {
            //Delete Product API
            if (willShip) {
                await DeleteProfession(id).then((result) => {
                    if (result.status) {
                        swal(result.message, {
                            icon: "success",
                        });
                        getProfessions()
                    } else {
                        toast.error(result.message);
                    }
                }).catch((error) => {
                    if (error.response.status == 401) {
                        EmptyLocalStorage()
                        history.push('/');
                    } else {
                        return toast.error(error.response.data.message);
                    }
                })
            }
        });
    }

    const userActions = (data) => {
        return (
            <DropdownButton align="end" className={"actionDropdown"} id="dropdown-item-button" title={<MenuDots/>}>
                {/*<Dropdown.Item as="button"  onClick={() => {
                    setSelectedName(data.name)
                    setViewModalShow(true)
                    setViewModalData(data)
                    setValue('id', data.id);
                    setValue('name', data.name);
                }}>View</Dropdown.Item>*/}
                <Dropdown.Item as="button"  onClick={() => {
                    setSelectedName(data.name)
                    setEditModalShow(true)
                    setEditModalData(data.Id)
                    setValue('id', data.Id);
                    setValue('name', data.name);
                }}>Edit</Dropdown.Item>
                <Dropdown.Item as="button" onClick={() => onProfessionDelete(data.id)}>Delete</Dropdown.Item>
            </DropdownButton>
        )
    }

    const onDelete = (id) => {
        swal({
            title: "Are you sure?",
            text: "Are you sure you want to delete the selected Profession?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willDelete) => {
            if (willDelete) {
                await DeleteProfession(id).then((result) => {
                    if (result.status) {
                        swal(result.message, {
                            icon: "success",
                        });
                        getProfessions()
                    } else {
                        toast.error(result.message);
                    }
                }).catch((error) => {
                    if (error.response.status == 401) {
                        EmptyLocalStorage()
                        history.push('/');
                    } else {
                        return toast.error(error.response.data.message);
                    }
                })
            }
        });
    }

    const onDeleteAll = () => {
        swal({
            title: "Are you sure?",
            text: "Are you sure you want to delete the selected rows?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willDelete) => {
            if (willDelete) {
                await DeleteAllProfession({ids: selectedIds}).then((result) => {
                    if (result.status) {
                        swal(result.message, {
                            icon: "success",
                        });
                        setSelectedIds([])
                        setIsCheckAll(false)
                        getProfessions()
                    } else {
                        toast.error(result.message);
                    }
                }).catch((error) => {
                    return toast.error(error.response.data.message);
                })
            }
        });
    }

    const columns = [
        /*{
            id: 'delete',
            maxWidth: "0%",
            selector: rowData => rowData.index,
        },*/
        {
            id: 'name',
            name: 'Name',
            selector: rowData => rowData.name,
        },

        {
            id: 'action',
            name: 'Action',
            cell: rowData => userActions(rowData)
        },
    ];
    const onModalSubmit = async (data) => {
        let formData = new FormData();
        formData.append('name', data.name);

        await CreateProfession(formData).then((data) => {
            if (data.status) {
                toast.success(data.message);
                getProfessions();
                setModalShow(false)
                reset();
            } else {
                toast.error(data.message);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })
    };

    const onEditModalSubmit = async (data) => {
        let id = data.id
        let formData = new FormData();
        formData.append('name', data.name);

        await UpdateProfession(id, formData).then((data) => {
            if (data.status) {
                toast.success(data.message);
                getProfessions()
                reset();
                setEditModalShow(false)
            } else {
                toast.error(data.message);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })
    };

    const handleChange = (event) => {
        // setFileData(URL.createObjectURL(event.target.files[0]));
    }

    /*
    *
    *  form elements inside popup
    *
    * */

    const ModalForm = () => {
        return (
            <div className={"modalBodyContent"}>
                <Form encType="multipart/form-data" onSubmit={handleSubmit(onModalSubmit)}>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Form.Group className="mb-3" controlId="name">
                                {/*En*/}
                                <Form.Label>Name<small style={{color: "red"}}>*</small></Form.Label>
                                <Form.Control
                                    placeholder="Type Name"
                                    id={"name"}
                                    name="name"
                                    autocomplete="off"
                                    {...register('name', {
                                        onChange: (e) => {
                                            setValue('name', e.target.value)
                                        },
                                        required: {
                                            value: "required",
                                            message: "Name is required"
                                        },
                                        maxLength: {
                                            value: 255,
                                            message: "Max length is 255"
                                        },
                                    })} type="text"
                                />
                                {errors.name && <Form.Text
                                    className="text-muted validationText hasError">{errors.name.message}</Form.Text>}
                            </Form.Group>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <input type={"submit"} disabled={disableButton} className={"btn btn-green-theme py-2 px-12 mt-3"}
                                   value={"Submit"}/>
                        </Col>
                    </Row>
                </Form>
            </div>
        )
    }

    const EditModalForm = () => {
        return (
            <div className={"modalBodyContent"}>
                <Form className={"form-updateType"} onSubmit={handleSubmit(onEditModalSubmit)}>
                    <Form.Control
                        {...register('id')} type="hidden"/>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Form.Group className="mb-3" controlId="name">
                                <Form.Label>Name<small style={{color: "red"}}>*</small></Form.Label>
                                <Form.Control
                                    placeholder="Type Name"
                                    autocomplete="off"
                                    defaultValue={selectedName}
                                    {...register('Name', {
                                        onChange: (e) => {
                                            setValue('Name', e.target.value)
                                        },
                                        required: {
                                            value: "required",
                                            message: "Name is required"
                                        },
                                        maxLength: {
                                            value: 255,
                                            message: "Max Length is 255"
                                        }
                                    })} type="text"
                                />
                                {errors.name && <Form.Text
                                    className="text-muted validationText hasError">{errors.name.message}</Form.Text>}
                            </Form.Group>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <input type={"submit"} disabled={disableButton} className={"btn btn-green-theme py-2 px-12 mt-3"}
                                   value={"Submit"}/>
                        </Col>
                    </Row>
                </Form>
            </div>
        )
    }

    const ViewModalForm = () => {
        return (
            <div className={"modalBodyContent"}>
                <Form className={"form-updateType"} >
                    <Form.Control
                        {...register('id')} type="hidden"/>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Form.Group className="mb-3" controlId="name">
                                <Form.Label>Name<small style={{color: "red"}}>*</small></Form.Label>
                                <Form.Control
                                    placeholder="Type Name"
                                    autocomplete="off"
                                    defaultValue={viewModalData.name}
                                    {...register('name', {
                                        onChange: (e) => {
                                            setValue('name', e.target.value)
                                        },
                                        required: {
                                            value: "required",
                                            message: "Name is required"
                                        },
                                        maxLength: {
                                            value: 255,
                                            message: "Max Length is 255"
                                        }
                                    })} type="text"
                                />
                                {errors.name && <Form.Text
                                    className="text-muted validationText hasError">{errors.name.message}</Form.Text>}
                            </Form.Group>
                        </Col>

                    </Row>
                </Form>
            </div>
        )
    }

    return (
        <div className={"manageType"}>
            <h3 className="page-heading">Professions</h3>
            <hr/>
            <div className={"customOrderFilters"}>
                <Row>
                    <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                        <InputGroup className="mb-3 search-group">
                            <InputGroup.Text id="basic-addon1"><SearchIcon/></InputGroup.Text>
                            <FormControl
                                placeholder="Search"
                                aria-label="Search"
                                aria-describedby="Search"
                                maxLength={"255"}
                                onChange={(e) => {
                                    (e.target.value.length > 1) ? setKeyword(e.target.value) : setKeyword(null)
                                }}
                            />
                        </InputGroup>
                    </Col>

                    <Col xs={12} sm={12} md={7} lg={7} xl={7}>
                        <Row>
                            <Col xs={12} sm={12} md={3} lg={3} xl={3}></Col>
                            <Col xs={12} sm={12} md={5} lg={5} xl={5}></Col>
                            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                <Button className={"btn btn-green-theme h40 w-100"} onClick={() => setModalShow(true)}>
                                    <AddIconOutline/> Add New
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            <div className={"mt-3"}>
                <div className="theme-dataGrid products-dataGrid">
                    <DataTable
                        columns={columns}
                        // data={rows}
                        data={rows}
                        progressPending={loading}
                        customStyles={customStyles}
                        selectableRows={false}
                        striped
                        highlightOnHover
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        paginationPerPage={perPage}
                        onChangePage={(page) => {
                            setPage(page);
                        }}
                        onChangeRowsPerPage={(currentRowsPerPage) => {
                            setPerPage(currentRowsPerPage)
                        }}
                        progressComponent={<BarLoader color={'#3D2570'} loading={loading} css={'marginTop: 10px'}
                                                      height={'4'} width={'100%'}/>}
                    />
                </div>
            </div>
            {/* Popup*/}
            <ThemeModal title={"Add New Profession"} size={"md"} content={<ModalForm/>} show={modalShow}
                        onHide={() => setModalShow(false)}/>
            <ThemeModal title={"Edit Profession"} size={"md"} content={<EditModalForm/>} show={editModalShow}
                        onHide={() => setEditModalShow(false)}/>
            <ThemeModal title={"View Profession"} size={"md"} content={<ViewModalForm/>} show={viewModalShow}
                        onHide={() => setViewModalShow(false)}/>
        </div>
    )
}

export default Professions
