import {apiUrl} from '../utils/constants';
import {GET, POST, PUT, DELETE} from './api.service.wrapper';

export const GetOffensive_Words = async (page = '', perPage = '', keyword = '') => {
    let params = {};

    if (page) {
        params['page'] = page;
    }

    if (perPage) {
        params['per-page'] = perPage;
    }

    if (keyword) {
        params['keyword'] = keyword;
    }

    return await GET(apiUrl.offensive_words, params);
}

export const GetOffensive_WordsById = async (id) => {
    return  await GET(apiUrl.offensive_words + '/' + id);
}

export const CreateOffensive_Words = async (data) => {
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }
    return await POST(apiUrl.offensive_words, data, config);
}

export const UpdateOffensive_Words = async (id, data) => {
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }
    return await PUT(apiUrl.offensive_words, id, data, config);
}

export const DeleteOffensive_Words= async (id) => {
    return await DELETE(apiUrl.offensive_words, id);
}

export const DeleteAllOffensive_Words = async (ids) => {
    return await POST(apiUrl.delete_all_offensive_words, ids);
}
