import React, {createContext} from "react";
import {BrowserRouter as Router, Route, Switch, Redirect} from "react-router-dom";
//auth layout
import AuthLayoutRoute from "./layouts/AuthLayout";
//auth views
import Signin from "./views/auth/Signin";
import SignUp from "./views/auth/SignUp";
import ForgotPassword from "./views/auth/ForgotPassword";
import VerifyCode from "./views/auth/VerifyCode";
import ResetPassword from "./views/auth/ResetPassword";
//dashboard layout
import DashboardLayoutRoute from "./layouts/DashboardLayout";
//dashboard views
import Dashboard from "./views/dashboard/Dashboard";
//dashboard views / Users
import Users from "./views/users/Users";
import AddNewUser from "./views/users/AddNewUser";
import EditUser from "./views/users/EditUser";
//notifications
import AllNotifications from "./views/notification/AllNotifications";

// dashboard settings
import ChangePassword from "./views/settings/ChangePassword";

//Policy routes
import Pages from "./views/policy/Pages";

//profile management
import ViewProfile from "./views/profile/ViewProfile";
import EditProfile from "./views/profile/EditProfile";
//error layout
import ErrorComponent from './views/error/ErrorComponent'




//pages
import PageList from "./views/pages/PageList"
import PageAdd from "./views/pages/PageAdd";
import PageEdit from "./views/pages/PageEdit";

import Payments from "./views/payments/Payments";

import {injectStyle} from "react-toastify/dist/inject-style";
import {ToastContainer} from "react-toastify";
import ProfessionalUsers from "./views/users/ProfessionalUsers";
import UserDetails from "./views/users/UserDetails";
import ReportedContent from "./views/reportedContent/ReportedContent";
import Settings from "./views/settings/Settings";
import Analytics from "./views/analytics/Analytics";
import Avatars from "./views/avatars/Avatars";
import Faq from "./views/faq/Faq";
import Charities from "./views/charities/Charities";
import OffensiveWords from "./views/offensive_words/OffensiveWords";
import Professions from "./views/professions/Professions";
import ReportTypes from "./views/report_types/ReportTypes";
import Tokens from "./views/tokens/Tokens";
import TokenList from "./views/tokens/TokenList";
import EditTokens from "./views/tokens/EditTokens";
import ViewTokens from "./views/tokens/ViewTokens";

// CALL IT ONCE IN YOUR APP
if (typeof window !== "undefined") {
    injectStyle();
}

function App() {
    return (
        <Router>
            <Switch>
                {/**
                 *
                 *  Define all the page routes here
                 *
                 */}
                <DashboardLayoutRoute exact isAuth={true} path={`/dashboard`} component={Dashboard}/>

                <DashboardLayoutRoute exact isAuth={true} path={`/professional-users`} component={ProfessionalUsers}/>
                <DashboardLayoutRoute exact isAuth={true} path={`/manage-users`} component={Users}/>
                <DashboardLayoutRoute exact isAuth={true} path={`/manage-users/:id`} component={UserDetails}/>

                <DashboardLayoutRoute exact isAuth={true} path={`/avatars`} component={Avatars}/>

                <DashboardLayoutRoute exact isAuth={true} path={`/manage-users/add-user`} component={AddNewUser}/>
                <DashboardLayoutRoute exact isAuth={true} path={`/manage-users/edit-user/:id`}
                                      component={EditUser}/>
                <DashboardLayoutRoute exact isAuth={true} path={`/notifications`} component={AllNotifications}/>
                {/*payouts*/}
                <DashboardLayoutRoute exact isAuth={true} path={`/payments-management`} component={Payments}/>
                {/*Reported Content*/}
                <DashboardLayoutRoute exact isAuth={true} path={`/reported-content`} component={ReportedContent}/>
                <DashboardLayoutRoute exact isAuth={true} path={`/settings`} component={Settings}/>

                {/**
                 *
                 *  pages Management
                 *
                 */}
                <DashboardLayoutRoute exact isAuth={true} path={`/page-list`} component={PageList}/>
                <DashboardLayoutRoute exact isAuth={true} path={`/page-edit/:id`} component={PageEdit}/>
                <DashboardLayoutRoute exact isAuth={true} path={`/page-add`} component={PageAdd}/>
                <DashboardLayoutRoute exact isAuth={true} path={`/faqs`} component={Faq}/>
                <DashboardLayoutRoute exact isAuth={true} path={`/charities`} component={Charities}/>
                <DashboardLayoutRoute exact isAuth={true} path={`/offensive-words`} component={OffensiveWords}/>
                <DashboardLayoutRoute exact isAuth={true} path={`/professions`} component={Professions}/>
                <DashboardLayoutRoute exact isAuth={true} path={`/report-types`} component={ReportTypes}/>
                <DashboardLayoutRoute exact isAuth={true} path={`/add-tokens`} component={Tokens}/>
                <DashboardLayoutRoute exact isAuth={true} path={`/view-token/:id`} component={ViewTokens} />
                <DashboardLayoutRoute exact isAuth={true} path={`/list-tokens`} component={TokenList}/>
                <DashboardLayoutRoute exact isAuth={true} path={`/edit-tokens/:id`} component={EditTokens}/>



                <DashboardLayoutRoute exact isAuth={true} path={`/analytics`} component={Analytics}/>


                {/**
                 *
                 *  Terms and conditions pages
                 *
                 */}
                <DashboardLayoutRoute exact isAuth={true} path={`/page/:slug`} component={Pages}/>

                {/**
                 *
                 *  Profile Views
                 *
                 */}
                <DashboardLayoutRoute exact isAuth={true} path={`/profile`} component={ViewProfile}/>
                <DashboardLayoutRoute exact isAuth={true} path={`/edit-profile`} component={EditProfile}/>
                <DashboardLayoutRoute exact isAuth={true} path={`/change-password`} component={ChangePassword}/>


                {/**
                 *
                 *  Authentication routes
                 *
                 */}
                <AuthLayoutRoute exact isAuth={true} path="/sign-up" component={SignUp}/>
                <AuthLayoutRoute exact isAuth={true} path="/forgot-password" component={ForgotPassword}/>
                <AuthLayoutRoute exact isAuth={true} path="/verify-code" component={VerifyCode}/>
                <AuthLayoutRoute exact isAuth={true} path="/reset-password" component={ResetPassword}/>
                <AuthLayoutRoute exact isAuth={true} path="/" component={Signin}/>

                {/**
                 *
                 *  Error routes
                 *
                 */}
                <Route path={"*"}>
                    <ErrorComponent type={"404"} message={"The page you are looking for is not found"}/>
                </Route>


            </Switch>
            <ToastContainer hideProgressBar autoClose={2000}/>
        </Router>
    );
}

export default App;
