import React, {useState, useEffect} from 'react'
import {Button, Col, Dropdown, DropdownButton, Form, FormControl, InputGroup, Row} from "react-bootstrap"
import {MenuDots, SearchIcon, StatusIcon} from "../../components/SvgIcons"
import {useHistory} from 'react-router-dom';
import {toast} from "react-toastify";
import {constants, customStyles} from "../../utils/constants";
import moment from "moment";
import DataTable from 'react-data-table-component';
import BarLoader from "react-spinners/BarLoader";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import {GetReport, CreateReport} from "../../services/report.service";
import {UpdateStatus} from "../../services/csa/orders.service";

function ReportedContent() {
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(constants.PERPAGE);
    const [rows, setRows] = useState();
    const [keyword, setKeyword] = useState('');

    useEffect(async () => {
        await getReports();
    }, [keyword, page, perPage])

    const getReports = async () => {
        setLoading(true)
        await GetReport(page, perPage, keyword).then((result) => {
            if (result.status) {
                if (result.data.data) {
                    const rowData = [];
                    setPage(result.data.meta.current_page)
                    setTotalRows(result.data.meta.total);

                    result.data.data.map((dt, index) => {
                        if(dt.user) rowData.push({
                            id: dt?.id,
                            index: ++index,
                            user:  dt?.user.username || '-',
                            report_type: dt?.report_type.name ||  '',
                            description: dt?.description || '-',
                            status: dt?.status || 0,
                        });
                    })
                    setRows(rowData);
                    setLoading(false)
                }
            } else {
                setLoading(false)
                return toast.error(result.message);
            }
        }).catch((error) => {
            setLoading(false)
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })
    }

    const columns = [
        /*{
            id: 'delete',
            maxWidth: "0%",
            selector: rowData => rowData.index
        },*/
        {
            id: 'report_type',
            name: 'Report Type',
            selector: rowData => rowData.report_type,
        },
        {
            id: 'user',
            name: 'User',
            selector: rowData => rowData.user,
        },
        {
            id: 'description',
            name: 'Description',
            selector: rowData => rowData.description,
        },
        /*{
            id: 'status',
            name: 'Status',
            selector: rowData => rowData.status,
        },*/
    ];

    return (
        <div className={"usersPage"}>
            <h3 className="page-heading">Reported Content</h3>
            <hr/>
            <div className={"customOrderFilters"}>
                <Row>
                    <Col xs={6} sm={6} md={5} lg={5} xl={5}>
                        <InputGroup className="mb-3 search-group">
                            <InputGroup.Text id="basic-addon1"><SearchIcon/></InputGroup.Text>
                            <FormControl
                                placeholder="Search"
                                aria-label="Search"
                                aria-describedby="Search"
                                maxLength={"255"}
                                onChange={(e) => {
                                    (e.target.value.length > 2) ? setKeyword(e.target.value) : setKeyword(null)
                                }}
                            />
                        </InputGroup>
                    </Col>
                </Row>
            </div>
            <div className="theme-dataGrid products-dataGrid">
                <DataTable
                    columns={columns}
                    data={rows}
                    progressPending={loading}
                    customStyles={customStyles}
                    selectableRows={false}
                    striped
                    highlightOnHover
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationPerPage={perPage}
                    onChangePage={(page) => {
                        setPage(page);
                    }}
                    onChangeRowsPerPage={(currentRowsPerPage) => {
                        setPerPage(currentRowsPerPage)
                    }}
                    progressComponent={<BarLoader color={'#3D2570'} loading={loading} css={'marginTop: 10px'}
                                                  height={'4'} width={'100%'}/>}
                />
            </div>
        </div>
    )
}

export default ReportedContent
