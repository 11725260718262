import React, {useEffect, useState} from 'react'
import {AddIconOutline, MenuDots, SearchIcon, UploadIcon} from "../../components/SvgIcons";
import {Button, Col, Dropdown, DropdownButton, Form, FormControl, InputGroup, Row} from "react-bootstrap"
import {useHistory} from "react-router-dom";
import swal from "sweetalert";
import ThemeModal from "../../components/ThemeModal";
import {useForm} from "react-hook-form";
import Profile from "../../assets/images/profile.jpg"


import {toast} from "react-toastify";
import {constants, customStyles} from "../../utils/constants";
import DataTable from 'react-data-table-component';
import BarLoader from "react-spinners/BarLoader";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import {FaEdit, FaTrash} from "react-icons/fa";
import {CreateCharities, DeleteCharities, GetCharities, UpdateCharities} from "../../services/charity.service";

function Charities() {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(constants.PERPAGE);
    const [rows, setRows] = useState();
    const [keyword, setKeyword] = useState('');
    const [modalShow, setModalShow] = useState(false);
    const [editModalShow, setEditModalShow] = useState(false);
    const [editModalData, setEditModalData] = useState([]);
    const [viewModalShow, setViewModalShow] = useState(false);
    const [viewModalData, setViewModalData] = useState([]);
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [selectedIds, setSelectedIds] = useState([]);
    const {register, handleSubmit, reset, setValue, formState: {errors}} = useForm({
        mode: "onChange"
    });
    const onCharitiesDelete = (id) => {
        swal({
            title: "Are you sure?",
            text: "Are you sure you want to delete this Charity?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willShip) => {
            //Delete Product API
            if (willShip) {
                await DeleteCharities(id).then((result) => {
                    if (result.status) {
                        swal(result.message, {
                            icon: "success",
                        });
                        getCharities()
                    } else {
                        toast.error(result.message);
                    }
                }).catch((error) => {
                    if (error.response.status == 401) {
                        EmptyLocalStorage()
                        history.push('/');
                    } else {
                        return toast.error(error.response.data.message);
                    }
                })
            }
        });
    }
    const onView = (data) => {
        history.push('/profile-details/' + data.id, {
            data: data
        });
    }
    const userActions = (data) => {
        return (
            <DropdownButton align="end" className={"actionDropdown"} id="dropdown-item-button" title={<MenuDots/>}>
                {/*<Dropdown.Item as="button" onClick={() => {
                    setViewModalShow(true)
                    setViewModalData(data)
                    setValue('id', data.Id);
                    setValue('name', data.name);
                    setValue('description', data.description);
                }}>View</Dropdown.Item>*/}
                <Dropdown.Item as="button" onClick={() => {
                    setEditModalShow(true)
                    setEditModalData(data.Id)
                    setValue('id', data.Id);
                    setValue('name', data.name);
                    setValue('description', data.description);
                }}>Edit</Dropdown.Item>
                <Dropdown.Item as="button" onClick={() => onCharitiesDelete(data.Id)}>Delete</Dropdown.Item>
            </DropdownButton>
        )
    }
    const [disableButton, setDisableButton] = useState(false);

    const columns = [
        {
            id: "image",
            name: "Avatar",
            sortable: false,
            selector: (rowData) => <img src={rowData.image} className="img-fluid img-thumbnail" alt="" width={50} height={50}/>,
        },
        {
            id: "avatarName",
            name: "Name",
            selector: (rowData) => rowData.name,
        },
        {
            id: "avatarDescription",
            name: "Description",
            selector: (rowData) => rowData.description,
        },
        {
            id: "action",
            name: "Action",
            cell: (rowData) => userActions(rowData),
        },
    ];
    const data = [
        {
            id: 1,
            image: "https://i.ibb.co/BKdVnvS/avatar1-lg.png",
            avatar_name: "Corey Morgan",
            description: "very good"
        },
        {
            id: 2,
            image: "https://i.ibb.co/6JJ5SZc/avatar5-lg.png",
            avatar_name: "Corey Morgan",
            description: "very good"
        },
        {
            id: 3,
            image: "https://i.ibb.co/6YbGXYp/avatar2-lg.png",
            avatar_name: "Corey Morgan",
            description: "good"
        },
        {
            id: 4,
            image: "https://i.ibb.co/4KRy79c/avatar3-lg.png",
            avatar_name: "Corey Morgan",
            description: "very good"
        },
        {
            id: 5,
            image: "https://i.ibb.co/2qCHQFs/avatar4-lg.png",
            avatar_name: "Corey Morgan",
            description: "very good"
        },
    ];


    useEffect(() => {
        getCharities()
    }, [page, perPage, keyword])

    useEffect(async () => {
        if (!modalShow && !editModalShow) {
            // setFileData(false)
            setEditModalData([])
            reset();
        }
    }, [modalShow, editModalShow])

    /*useEffect(async () => {
        await getCharities();
    }, [])*/

    const getCharities = async () => {
        setLoading(true)
        await GetCharities(page, perPage, keyword).then((result) => {
            if (result.status) {
                if (result.data) {
                    const rowData = [];
                    // setPage(result.data.meta.current_page)
                    // setTotalRows(resulta.meta.current_page)
                    // setTotalRows(result.data.meta.total);
                    result.data.map((dt, index) => {
                        rowData.push({
                            id: dt.id,
                            index: ++index,
                            name: dt.name || '-',
                            image: Profile || dt.image,
                            description: dt.description || '-',
                        });
                    })
                    setRows(rowData);
                    setLoading(false)
                }
            } else {
                setLoading(false)
                return toast.error(result.message);
            }
        }).catch((error) => {
            setLoading(false)
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })
    }

    const onDelete = (id) => {
        swal({
            title: "Are you sure?",
            text: "Are you sure you want to delete the selected Charity?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willDelete) => {
            if (willDelete) {
                await DeleteCharities(id).then((result) => {
                    if (result.status) {
                        swal(result.message, {
                            icon: "success",
                        });
                        getCharities()
                    } else {
                        toast.error(result.message);
                    }
                }).catch((error) => {
                    if (error.response.status == 401) {
                        EmptyLocalStorage()
                        history.push('/');
                    } else {
                        return toast.error(error.response.data.message);
                    }
                })
            }
        });
    }

    const Actions = (data) => {
        const [disableButton, setDisableButton] = useState(false);


        return (
            <span>
                <a href="#" className={"text-black action-icon-fontSize"} onClick={() => {

                    setEditModalShow(true)
                    setEditModalData(data.Id)
                    setValue('id', data.Id);
                    setValue('question', data.question);
                    setValue('answer', data.answer);

                }}><FaEdit/></a>  &nbsp;
                <a href="#" className={"text-black action-icon-fontSize"}
                   onClick={() => onDelete(data.Id)}><FaTrash/></a>
            </span>
        )

        // return (
        //     <DropdownButton align="end" className={"actionDropdown"} id="dropdown-item-button" title={<MenuDots/>}>
        //         <Dropdown.Item as="button" onClick={async () => {
        //             setEditModalShow(true)
        //             setEditModalData(data.Id)
        //             setValue('id', data.Id);
        //             setValue('question', data.question);
        //             setValue('answer', data.answer);
        //         }}>
        //             Edit
        //         </Dropdown.Item>
        //         <Dropdown.Item as="button" onClick={() => onDelete(data.Id)}>Delete</Dropdown.Item>
        //     </DropdownButton>
        // )
    }


    const onModalSubmit = async (data) => {
        let formData = new FormData();
        formData.append('question', data.question);
        formData.append('answer', data.answer);

        await CreateCharities(formData).then((data) => {
            if (data.status) {
                toast.success(data.message);
                getCharities();
                setModalShow(false)
                reset();
            } else {
                toast.error(data.message);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })
    };

    const onEditModalSubmit = async (data) => {
        let id = data.id
        let formData = new FormData();
        formData.append('question', data.question);
        formData.append('answer', data.answer);

        await UpdateCharities(id, formData).then((data) => {
            if (data.status) {
                toast.success(data.message);
                getCharities()
                reset();
                setEditModalShow(false)
            } else {
                toast.error(data.message);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })
    };

    const handleChange = (event) => {
        // setFileData(URL.createObjectURL(event.target.files[0]));
    }

    /*
    *
    *  form elements inside popup
    *
    * */

    const ModalForm = () => {
        return (
            <div className={"modalBodyContent"}>
                <Form encType="multipart/form-data" onSubmit={handleSubmit(onModalSubmit)}>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Form.Group className="mb-3" controlId="Name">
                                {/*En*/}
                                <Form.Label>Name<small style={{color: "red"}}>*</small></Form.Label>
                                <Form.Control
                                    placeholder="Type Name"
                                    id={"Name"}
                                    name="Name"
                                    autocomplete="off"
                                    {...register('Name', {
                                        onChange: (e) => {
                                            setValue('Name', e.target.value)
                                        },
                                        required: {
                                            value: "required",
                                            message: "Name is required"
                                        },
                                        maxLength: {
                                            value: 255,
                                            message: "Max length is 255"
                                        },
                                    })} type="text"
                                />
                                {errors.question && <Form.Text
                                    className="text-muted validationText hasError">{errors.name.message}</Form.Text>}
                            </Form.Group>
                        </Col>

                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Form.Group className="mb-3" controlId="answer">
                                {/*En*/}
                                <Form.Label>Description<small style={{color: "red"}}>*</small></Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={10}
                                    placeholder="Type Description"
                                    id={"answer"}
                                    name="answer"
                                    autocomplete="off"
                                    {...register('answer', {
                                        onChange: (e) => {
                                            setValue('answer', e.target.value)
                                        },
                                        required: {
                                            value: "required",
                                            message: "Description is required"
                                        },
                                        maxLength: {
                                            value: 1000,
                                            message: "Max length is 1000"
                                        },
                                    })} type="text"
                                />
                                {errors.answer && <Form.Text
                                    className="text-muted validationText hasError">{errors.answer.message}</Form.Text>}
                            </Form.Group>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6} className={"mb-3"}>
                            <img onError={({currentTarget}) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = constants.IMAGENOTFOUND;
                            }} src={"https://i.ibb.co/4KRy79c/avatar3-lg.png"} className={"img-fluid img-thumbnail"} width={100} height={100}/>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Form.Group controlId="formFile"
                                        className="custom-file-upload mb-0">
                                <Form.Label className={"toggleFileUpload mb-0"}>
                                    <UploadIcon/>
                                    Upload Image
                                </Form.Label>
                                <Form.Control type="file"
                                              accept="image/png, image/jpeg, image/jpg, image/gif" {...register('media')} />

                            </Form.Group>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <input type={"submit"} className={"btn btn-green-theme w-100 mt-3"} value={"Submit"}/>
                        </Col>
                    </Row>
                </Form>
            </div>
        )
    }
    const ViewModalForm = () => {
        console.log(viewModalData.name)
        console.log()
        return (
            <div className={"modalBodyContent"}>
                <Form className={"form-updateType"}>
                    <Form.Control
                        {...register('id')} type="hidden"/>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Form.Group className="mb-3" controlId="name">
                                <Form.Label>Name<small style={{color: "red"}}>*</small></Form.Label>
                                <Form.Control
                                    placeholder="Type Question"
                                    autocomplete="off"
                                    {...register('name', {
                                        onChange: (e) => {
                                            setValue('question', e.target.value)
                                        },
                                        required: {
                                            value: "required",
                                            message: "Name is required"
                                        },
                                        maxLength: {
                                            value: 255,
                                            message: "Max Length is 255"
                                        }
                                    })} type="text"
                                    defaultValue={viewModalData.name}

                                />
                                {errors.question && <Form.Text
                                    className="text-muted validationText hasError">{errors.question.message}</Form.Text>}
                            </Form.Group>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6} className={"mb-3"}>
                            <img onError={({currentTarget}) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = constants.IMAGENOTFOUND;
                            }} src={viewModalData.image} className={"img-fluid img-thumbnail"} width={100} height={100}/>
                        </Col>
                        {/*<Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Form.Group className="mb-3" controlId="answer">
                                <Form.Label>Description<small style={{color: "red"}}>*</small></Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={10}
                                    placeholder="Type Answer"
                                    autocomplete="off"
                                    {...register('answer', {
                                        onChange: (e) => {
                                            setValue('description', e.target.value)
                                        },
                                        required: {
                                            value: "answer",
                                            message: "Description is required"
                                        },
                                        minLength: {
                                            value: 3,
                                            message: "Min Length is 3"
                                        },
                                        maxLength: {
                                            value: 1000,
                                            message: "Max Length is 1000"
                                        }
                                    })} type="text"
                                    defaultValue={viewModalData.description}

                                />
                                {errors.answer && <Form.Text
                                    className="text-muted validationText hasError">{errors.answer.message}</Form.Text>}
                            </Form.Group>
                        </Col>*/}
                    </Row>
                </Form>
            </div>
        )
    }

    const EditModalForm = () => {
        return (
            <div className={"modalBodyContent"}>
                <Form className={"form-updateType"} onSubmit={handleSubmit(onEditModalSubmit)}>
                    <Form.Control
                        {...register('id')} type="hidden"/>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Form.Group className="mb-3" controlId="question">
                                <Form.Label>Question<small style={{color: "red"}}>*</small></Form.Label>
                                <Form.Control
                                    placeholder="Type Question"
                                    autocomplete="off"
                                    {...register('question', {
                                        onChange: (e) => {
                                            setValue('question', e.target.value)
                                        },
                                        required: {
                                            value: "required",
                                            message: "Question is required"
                                        },
                                        maxLength: {
                                            value: 255,
                                            message: "Max Length is 255"
                                        }
                                    })} type="text"
                                />
                                {errors.question && <Form.Text
                                    className="text-muted validationText hasError">{errors.question.message}</Form.Text>}
                            </Form.Group>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Form.Group className="mb-3" controlId="answer">
                                <Form.Label>Answer<small style={{color: "red"}}>*</small></Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={10}
                                    placeholder="Type Answer"
                                    autocomplete="off"
                                    {...register('answer', {
                                        onChange: (e) => {
                                            setValue('answer', e.target.value)
                                        },
                                        required: {
                                            value: "answer",
                                            message: "Answer is required"
                                        },
                                        minLength: {
                                            value: 3,
                                            message: "Min Length is 3"
                                        },
                                        maxLength: {
                                            value: 1000,
                                            message: "Max Length is 1000"
                                        }
                                    })} type="text"
                                />
                                {errors.answer && <Form.Text
                                    className="text-muted validationText hasError">{errors.answer.message}</Form.Text>}
                            </Form.Group>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <input type={"submit"} className={"btn btn-green-theme w-100 mt-3"} value={"Submit"}/>
                        </Col>
                    </Row>
                </Form>
            </div>
        )
    }

    return (
        <div className={"manageType"}>
            <h3 className="page-heading">Charities</h3>
            <hr/>
            <div className={"customOrderFilters"}>
                <Row>
                    <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                        <InputGroup className="mb-3 search-group">
                            <InputGroup.Text id="basic-addon1"><SearchIcon/></InputGroup.Text>
                            <FormControl
                                placeholder="Search"
                                aria-label="Search"
                                aria-describedby="Search"
                                maxLength={"255"}
                                onChange={(e) => {
                                    (e.target.value.length > 1) ? setKeyword(e.target.value) : setKeyword(null)
                                }}
                            />
                        </InputGroup>
                    </Col>

                    <Col xs={12} sm={12} md={7} lg={7} xl={7}>
                        <Row>
                            <Col xs={12} sm={12} md={3} lg={3} xl={3}></Col>
                            <Col xs={12} sm={12} md={5} lg={5} xl={5}></Col>
                            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                <Button className={"btn btn-green-theme h40 w-100"} onClick={() => setModalShow(true)}>
                                    <AddIconOutline/> Add New
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            <div className="theme-dataGrid products-dataGrid">
            <DataTable
                columns={columns}
                // data={rows}
                data={rows}
                progressPending={loading}
                customStyles={customStyles}
                selectableRows={false}
                striped
                highlightOnHover
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                paginationPerPage={perPage}
                onChangePage={(page) => {
                    setPage(page);
                }}
                onChangeRowsPerPage={(currentRowsPerPage) => {
                    setPerPage(currentRowsPerPage)
                }}
                progressComponent={<BarLoader color={'#3D2570'} loading={loading} css={'marginTop: 10px'}
                                              height={'4'} width={'100%'}/>}
            />
            </div>
            {/* Popup*/}
            <ThemeModal title={"Add New Charity"} size={"md"} content={<ModalForm/>} show={modalShow}
                        onHide={() => setModalShow(false)}/>
            <ThemeModal title={"Edit Charity"} size={"md"} content={<EditModalForm/>} show={editModalShow}
                        onHide={() => setEditModalShow(false)}/>
            <ThemeModal title={"View Charity"} size={"md"} content={<ViewModalForm/>} show={viewModalShow}
                        onHide={() => setViewModalShow(false)}/>
        </div>
    )
}

export default Charities
