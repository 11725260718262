import {apiUrl} from '../utils/constants';
import {GET, POST, PUT, DELETE} from './api.service.wrapper';

export const GetFaqs = async (page = '', perPage = '', keyword = '') => {
    let params = {};

    if (page) {
        params['page'] = page;
    }

    if (perPage) {
        params['per-page'] = perPage;
    }

    if (keyword) {
        params['keyword'] = keyword;
    }

    return await GET(apiUrl.faqs, params);
}

export const GetFaqById = async (id) => {
    return  await GET(apiUrl.faqs + '/' + id);
}

export const CreateFaq = async (data) => {
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }
    return await POST(apiUrl.faqs, data, config);
}

export const UpdateFaq = async (id, data) => {
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }
    return await PUT(apiUrl.faqs, id, data, config);
}

export const DeleteFaq = async (id) => {
    return await DELETE(apiUrl.faqs, id);
}

export const DeleteAllFaqs = async (ids) => {
    return await POST(apiUrl.delete_all_faqs, ids);
}