import React, {useEffect, useState} from 'react'
import {GetUserRole} from "../../services/auth/auth.service"
import {AdminDashboard, VendorDashboard, CsaDashboard} from "./ByRole/index"
import {constants} from "../../utils/constants";

function Dashboard() {
    const [dashboard, setDashboard] = useState();

    {/**


    useEffect(() => {
        let role = GetUserRole();
        //  let role = 10;

        if(role == constants.ROLES.ROLE_ADMIN){
            setDashboard(<AdminDashboard RoleId={role} />);
        }
        else if(role == constants.ROLES.ROLE_VENDOR){
            setDashboard(<VendorDashboard RoleId={role} />);
        }
        else if(role == constants.ROLES.ROLE_CSA){
            setDashboard(<CsaDashboard RoleId={role} />);
        }
    }, []);

     */}

    return (
        <div>
            {/*{dashboard}*/}
            <AdminDashboard RoleId={1}/>
        </div>
    )
}

export default Dashboard
