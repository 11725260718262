import React, {useState, useEffect, useMemo} from 'react'
import Profile from "../../assets/images/profile.jpg"
import {MenuDots, Star} from "../../components/SvgIcons";
import {Dropdown, DropdownButton, Row, Col, Nav, Tab, Form, Button} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {WithContext as ReactTags} from "react-tag-input";
import {MdDeleteOutline} from "react-icons/md"
import DataTable from "react-data-table-component";
import {constants, customStyles} from "../../utils/constants";
import BarLoader from "react-spinners/BarLoader";
import {NavLink} from "react-router-dom";
import DashboardStat from "../../components/DashboardStat";
import {GetUserById, UpdateUser} from "../../services/users.service";
import {toast} from "react-toastify";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import {useHistory} from 'react-router-dom';
import countryList from 'react-select-country-list'

const KeyCodes = {
    comma: 188,
    enter: 13
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

const DetailForm = (props) => {
    const options = useMemo(() => countryList().getData(), [])
    const data = props.data == null ? [] : props.data[0]
    const [disableButton, setDisableButton] = useState(false);

    const {register, watch, reset, handleSubmit, formState: {errors}} = useForm({
        mode: "onBlur"
    });

    /*
   * React Tags Input ADD, delete and click
   * */
    // let [tags, setTags] = useState([
    //     { id: 'Thailand', text: 'Thailand' },
    //     { id: 'India', text: 'India' },
    //     { id: 'Vietnam', text: 'Vietnam' },
    //     { id: 'Turkey', text: 'Turkey' }
    // ]);
    let [tags, setTags] = useState()
    let tagsObj = []
    let done = 2
    useEffect(() => {
        if (data.tags != null || data.tags != undefined) {
            data.tags.forEach((d) => {
                tagsObj.push({id: d.tag, text: d.tag})
            })
            setTags(tagsObj)
        }
    }, [done])

    useEffect(() => {
        if (data) {
            reset({
                bio: data?.bio || '',
                email: data?.email || '',
                first_name: data?.first_name || '',
                id: data?.id || '',
                image: data?.image || '',
                last_name: data?.last_name || '',
                location: data?.location || '',
                name: data?.name || '',
                profession: data?.profession || '',
                tags: data?.tags || '',
                username: data?.username || '',
            })
        }

    }, [data])

    const handleDelete = i => {
        setTags(tags.filter((tag, index) => index !== i));
    };

    const handleAddition = tag => {
        setTags([...tags, tag]);
    };

    const handleTagClick = index => {
        console.log('The tag at index ' + index + ' was clicked');
    };

    const RemoveTagComponent = (props) => {
        const {className, onRemove} = props;
        return (
            <button onClick={onRemove} className={className}>
                <MdDeleteOutline/>
            </button>
        )
    }
    let history = useHistory();

    const onSubmit = async (data) => {
        //Submit Profile Details
        await UpdateUser(data.id, data).then((result) => {
            if (result.status) {
                return toast.success(result.message);
            } else {
                return toast.error(result.message);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                // history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })
    }

    return (
        <>
            <h3 className={"mt-3"}>Profile Details</h3>
            <form className={""} onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Form.Group className="mb-3" controlId="first_name">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control
                                placeholder="Enter First Name"

                                defaultValue={data.first_name}
                                {...register('first_name', {
                                    required: {
                                        value: "required",
                                        message: "First Name is required"
                                    },
                                    minLength: {
                                        value: 3,
                                        message: "min length is 3"
                                    },
                                    maxLength: {
                                        value: 25,
                                        message: "Max length is 25"
                                    },
                                })} type="text"
                            />
                            {errors.first_name && <Form.Text
                                className="text-muted validationText hasError">{errors.first_name.message}</Form.Text>}

                        </Form.Group>

                        <Form.Group className="mb-3" controlId="username">
                            <Form.Label>Username</Form.Label>
                            <Form.Control
                                placeholder="Enter Username"
                                defaultValue={data.username}
                                {...register('username', {
                                    required: {
                                        value: "required",
                                        message: "Username is required"
                                    },
                                    minLength: {
                                        value: 3,
                                        message: "min length is 3"
                                    },
                                    maxLength: {
                                        value: 25,
                                        message: "Max length is 25"
                                    },
                                })} type="text"
                            />
                            {errors.username && <Form.Text
                                className="text-muted validationText hasError">{errors.username.message}</Form.Text>}

                        </Form.Group>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Form.Group className="mb-3" controlId="last_name">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control
                                placeholder="Enter Last Name"
                                defaultValue={data.last_name}
                                {...register('last_name', {
                                    required: {
                                        value: "required",
                                        message: "Last Name is required"
                                    },
                                    minLength: {
                                        value: 3,
                                        message: "Min length is 3"
                                    },
                                    maxLength: {
                                        value: 25,
                                        message: "Max length is 25"
                                    },
                                })} type="text"
                            />
                            {errors.last_name && <Form.Text
                                className="text-muted validationText hasError">{errors.last_name.message}</Form.Text>}

                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Location</Form.Label>

                            <select className={"form-select"}

                                    {...register("location", {
                                            required: {
                                                value: "required",
                                                message: "Location is required"
                                            }
                                        }
                                    )}
                                    aria-label="Default Filters">
                                <option value=""> Select Location</option>
                                {
                                    options && options.map((dt) => {
                                        return <option selected={data?.location == dt.label || ""}
                                                       value={dt.label}>{dt.label}</option>
                                    })
                                }
                                {/*<option value="Tx">{data.location}</option>*/}
                            </select>

                            {errors.location && <Form.Text
                                className="text-muted validationText hasError">{errors.location.message}</Form.Text>}
                        </Form.Group>
                    </Col>

                    {
                        data.role_id == constants.ROLES.ROLE_PROFESSIONAL ?
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group className="mb-3" controlId="bio">
                                    <Form.Label>Bio</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={10}
                                        placeholder="Bio"
                                        defaultValue={data.bio}

                                        {...register('bio', {
                                            required: {
                                                value: "required",
                                                message: "Bio is required"
                                            },
                                            maxLength: {
                                                value: 500,
                                                message: "max length is 500"
                                            },
                                        })} type="text"/>
                                    {errors.bio && <Form.Text
                                        className="text-muted validationText hasError">{errors.bio.message}</Form.Text>}
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Categories</Form.Label>
                                    <div className={"TagsContainer"}>
                                        <ReactTags
                                            tags={tags}
                                            delimiters={delimiters}
                                            handleDelete={handleDelete}
                                            handleAddition={handleAddition}
                                            handleTagClick={handleTagClick}
                                            removeComponent={RemoveTagComponent}
                                            inputFieldPosition="inline"
                                            autofocus={false}
                                            autocomplete
                                        />
                                    </div>
                                </Form.Group>

                            </Col> : ''
                    }

                    <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                        <input type={"submit"} disabled={disableButton} className={"btn btn-green-theme w-100 mt-3"}
                               value={"Submit"}/>
                    </Col>
                </Row>
            </form>
        </>
    )
}

const PayoutsTable = () => {
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(constants.PERPAGE);
    const [rows, setRows] = useState();
    const columns = [
        {
            id: 'date',
            name: 'date',
            maxWidth: '160px',
            selector: rowData => rowData.date,
        },
        {
            id: 'requested',
            name: 'Requested',
            maxWidth: '160px',
            selector: rowData => rowData.requested,
        },
        {
            id: 'paid',
            name: 'Paid',
            selector: rowData => rowData.paid,
        },
        {
            id: 'balance',
            name: 'Balance',
            selector: rowData => rowData.balance,
        },
        {
            id: 'paymentDate',
            name: 'Payment Date',
            selector: rowData => rowData.paymentDate,
        },
        {
            id: 'payStatus',
            name: 'Status',
            cell: rowData => payActions(rowData)
        },
    ];
    const data = [
        {
            id: 1,
            date: "May 19, 2022",
            requested: "$2,000",
            paid: "-",
            balance: "$2,000",
            paymentDate: "-",
            payStatus: false,
        },
        {
            id: 2,
            date: "Apr 10, 2022",
            requested: "$1,500",
            paid: "$1,500",
            balance: "$0",
            paymentDate: "Apr 20, 2022",
            payStatus: true,
        },
    ]

    const payActions = (data) => {
        if (data.payStatus === true) {
            return (
                <span>Completed</span>
            )
        } else {
            return (
                <Button type={"button"} className={"btn-primary"}>Pay Now</Button>
            )
        }

    }

    return (
        <>
            <h3 className={"mt-3"}>Profile Details</h3>
            <div className="theme-dataGrid products-dataGrid">
                <DataTable
                    columns={columns}
                    // data={rows}
                    data={data}
                    progressPending={loading}
                    customStyles={customStyles}
                    selectableRows={false}
                    striped
                    highlightOnHover
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationPerPage={perPage}
                    onChangePage={(page) => {
                        setPage(page);
                    }}
                    onChangeRowsPerPage={(currentRowsPerPage) => {
                        setPerPage(currentRowsPerPage)
                    }}
                    progressComponent={<BarLoader color={'#3D2570'} loading={loading} css={'marginTop: 10px'}
                                                  height={'4'} width={'100%'}/>}
                />
            </div>
        </>
    )
}

const AnalyyticsTab = () => {
    return (
        <>
            <h3 className={"mt-3"}>Analytics</h3>
            <Row className={"mb-4"}>
                <Col xs={12} sm={12} md={6} lg={4} xl={4}>
                    <NavLink to={"#"} className={"linkStats"}>
                        <DashboardStat title={"Messages replied"} countMain={"2,890"}/>
                    </NavLink>
                </Col>
                <Col xs={12} sm={12} md={6} lg={4} xl={4}>
                    <NavLink to={"#"} className={"linkStats"}>
                        <DashboardStat title={"Total earnings"} countMain={"$1,290"}/>
                    </NavLink>
                </Col>
                <Col xs={12} sm={12} md={6} lg={4} xl={4}>
                    <NavLink to={"#"} className={"linkStats"}>
                        <DashboardStat title={"Reported"} countMain={"$890"}/>
                    </NavLink>
                </Col>
            </Row>
        </>
    )
}

const ActivityTab = () => {
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(constants.PERPAGE);
    const [rows, setRows] = useState();

    const columns = [
        {
            id: 'date',
            name: 'date',
            maxWidth: '160px',
            selector: rowData => rowData.date,
        },
        {
            id: 'eventType',
            name: 'Event Type',
            maxWidth: '160px',
            selector: rowData => rowData.eventType,
        },
        {
            id: 'desc',
            name: 'Description',
            selector: rowData => rowData.desc,
        },
    ];

    const data = [
        {
            id: 1,
            date: "May 19, 2022",
            eventType: "Payment request",
            desc: "Emily Smith sent a withdrawal request of $3,400",
        },
        {
            id: 2,
            date: "Apr 10, 2022",
            eventType: "Payment request",
            desc: "Emily Smith sent a withdrawal request of $3,400",
        },
        {
            id: 3,
            date: "Apr 9, 2022",
            eventType: "Payment",
            desc: "A payment of $2,000 has been initiated for Emily Smith",
        },
        {
            id: 4,
            date: "Apr 5, 2022",
            eventType: "Verification",
            desc: "Emily Smith’s profile has been verified succesfully.",
        },
        {
            id: 5,
            date: "Apr 5, 2022",
            eventType: "Registration",
            desc: "Emily Smith has created an account.",
        },
    ]

    return (
        <>
            <h3 className={"mt-3"}>Profile Details</h3>
            <div className="theme-dataGrid products-dataGrid">
                <DataTable
                    columns={columns}
                    // data={rows}
                    data={data}
                    progressPending={loading}
                    customStyles={customStyles}
                    selectableRows={false}
                    striped
                    highlightOnHover
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationPerPage={perPage}
                    onChangePage={(page) => {
                        setPage(page);
                    }}
                    onChangeRowsPerPage={(currentRowsPerPage) => {
                        setPerPage(currentRowsPerPage)
                    }}
                    progressComponent={<BarLoader color={'#3D2570'} loading={loading} css={'marginTop: 10px'}
                                                  height={'4'} width={'100%'}/>}
                />
            </div>
        </>
    )
}

const UserDetails = (props) => {
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(constants.PERPAGE);
    const [rows, setRows] = useState();
    const [keyword, setKeyword] = useState('');
    const id = props.match.params.id


    useEffect(async () => {
        await getUsers();
    }, [])

    const getUsers = async () => {
        setLoading(true)
        await GetUserById(id).then((result) => {
            if (result.status) {
                const rowData = [];
                const dt = result.data
                // setPage(result.data.meta.current_page)
                // setTotalRows(result.data.meta.total);
                rowData.push({
                    id: dt.id,
                    first_name: dt.first_name || '-',
                    last_name: dt.last_name || '-',
                    username: dt.username || '-',
                    email: dt.email || '-',
                    location: dt.location,
                    image: dt.image == null || dt.image == "" ? Profile : dt.image,
                    profession: dt.profession != null ? dt.profession : dt.roles[0].name,
                    tokens: dt.total_tokens,
                    name: dt.name,
                    tags: dt.tags,
                    bio: dt.about,
                    role_id: dt.roles[0].id
                });
                setRows(rowData);
                setLoading(false)
            } else {
                setLoading(false)
                return toast.error(result.message);
            }
        }).catch((error) => {
            setLoading(false)
            if (error.response.status == 401) {
                EmptyLocalStorage()
                // history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })
    }

    return (
        <div className={"userDetails"}>
            <Row>
                <Col xs={12} sm={12} md={12} lg={{span: 10, offset: 1}} xl={{span: 10, offset: 1}}>
                    <div className={"profileDetails"}>
                        <div className={"img-container"}>
                            <img className={"img-fluid"} src={rows == null ? '' : rows[0].image} alt="profile"/>
                        </div>
                        <div className={"detailsContainer"}>
                            <h2>{rows == null ? '' : rows[0].name}</h2>
                            <ul className={"list-inline mb-0"}>
                                <li className={"list-inline-item"}>
                                    <span
                                        className={"Tag"}>{rows == null ? '' : rows[0].profession.name || rows[0].profession}</span>
                                </li>
                                <li className={"list-inline-item"}>
                                    <span className={"Tag"}><Star/> {rows == null ? '' : rows[0].tokens}</span>
                                </li>
                            </ul>
                        </div>
                        {/*<div className={"dropdownContainer"}>
                            <DropdownButton align="end" className={"actionDropdown"} id="dropdown-item-button" title={<MenuDots/>}>
                                <Dropdown.Item as="button" >Deactivate profile</Dropdown.Item>
                                <Dropdown.Item as="button" >Mark as verified</Dropdown.Item>
                                <Dropdown.Item as="button" >Reset password</Dropdown.Item>
                            </DropdownButton>
                        </div>*/}
                    </div>
                    <Row className={"mt-4"}>
                        <Col xs={12} sm={"12"} md={12} lg={12} xl={12}>
                            <div className={"customTabs"}>
                                <Tab.Container id="left-tabs-example" defaultActiveKey="profile">
                                    <Nav variant="pills" className="flex-row">
                                        <Nav.Item>
                                            <Nav.Link eventKey="profile">Profile Details</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="payouts">Payouts</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="analytics">Analytics</Nav.Link>
                                        </Nav.Item>
                                        {/*<Nav.Item>
                                            <Nav.Link eventKey="activity">Activity</Nav.Link>
                                        </Nav.Item>*/}
                                    </Nav>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="profile">
                                            <DetailForm data={rows}/>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="payouts">
                                            <PayoutsTable/>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="analytics">
                                            <AnalyyticsTab/>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="activity">
                                            <ActivityTab/>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Tab.Container>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>

        </div>
    )
}

export default UserDetails
