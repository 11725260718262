import {apiUrl} from '../utils/constants';
import {GET, POST, PUT, DELETE} from './api.service.wrapper';

export const GetProfession = async (page = '', perPage = '', keyword = '') => {
    let params = {};

    if (page) {
        params['page'] = page;
    }

    if (perPage) {
        params['per-page'] = perPage;
    }

    if (keyword) {
        params['keyword'] = keyword;
    }

    return await GET(apiUrl.professions, params);
}

export const GetProfessionById = async (id) => {
    return  await GET(apiUrl.professions + '/' + id);
}

export const CreateProfession = async (data) => {
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }
    return await POST(apiUrl.professions, data, config);
}

export const UpdateProfession = async (id, data) => {
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }
    return await PUT(apiUrl.professions, id, data, config);
}

export const DeleteProfession = async (id) => {
    return await DELETE(apiUrl.professions, id);
}

export const DeleteAllProfession = async (ids) => {
    return await POST(apiUrl.delete_all_professions, ids);
}
