import React, {useState, useEffect} from 'react'
import {Form, Row, Col} from "react-bootstrap";
import {useForm} from "react-hook-form";
// import {Editor} from "react-draft-wysiwyg";
import {toast} from "react-toastify";
import {useHistory} from 'react-router-dom';
import moment from "moment";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import {UploadIcon} from "../../components/SvgIcons";
import {CreateTokens, GetTokenById, UpdateToken} from "../../services/token.service";
import {CreatePage} from "../../services/pages.service";

function Tokens(props) {
    let history = useHistory();
    const [data, setData] = useState([]);

    const editorConfiguration = {
        toolbar: [ 'bold', 'italic', 'link', 'undo', 'redo', 'numberedList', 'bulletedList' ]
    };

    const {register, handleSubmit, reset, setValue, formState: {errors}} = useForm({
        mode: "onChange"
    });

    const onModalSubmit = async (data) => {
        console.log(data);
        await CreateTokens({
            id: data.id,
            name: data.name,
            tokens: parseInt(data.tokens),
            amount: parseInt(data.amount),
            admin_cut: parseInt(data.admin_cut),
            total_amount: parseInt(data.total_amount),
            saved_amount: parseInt(data.saved_amount),
            stripe_cut: parseInt(data.stripe_cut),
            status: data.status,
            // icons: data.media,
            recommended: data.recommended==1?true: false
        }).then((data) => {
            if (data.status) {
                toast.success(data.message);
                history.push('/list-tokens');
            }
            else{
                toast.error(data.message);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })
    };


    return (
        <div className={"EditToken"}>
            <h3 className="page-heading">Add Tokens</h3>
            <hr/>
            <Form onSubmit={handleSubmit(onModalSubmit)}>
                <div className={"PageEditForm mt-3"}>
                    <div className="">
                        <Row>
                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="name">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        placeholder="Name"
                                        defaultValue={data.name}
                                        {...register('name', {
                                            required: {
                                                value: "required",
                                                message: "Name is required"
                                            },
                                            minLength: {
                                                value: 5,
                                                message: "max length is 5"
                                            },
                                            maxLength: {
                                                value: 255,
                                                message: "max length is 255"
                                            },
                                        })} type="text"

                                    />
                                    {errors.name && <Form.Text
                                        className="text-muted validationText hasError">{errors.name.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="tokens">
                                    <Form.Label>Tokens</Form.Label>
                                    <Form.Control
                                        placeholder="Tokens"
                                        defaultValue={data.tokens}
                                        {...register('tokens', {
                                            required: {
                                                value: "required",
                                                message: "Tokens is required"
                                            },
                                        })} type="number"

                                    />
                                    {errors.tokens && <Form.Range
                                        className="text-muted validationNumber hasError">{errors.tokens.message}</Form.Range>}
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                    <div className="">
                        <Row>
                            <Col xs={12} sm={12} md={6} lg={12} xl={6}>
                                <Form.Group className="mb-3" controlId="amount">
                                    <Form.Label>Amount</Form.Label>
                                    <Form.Control
                                        placeholder="Amount"
                                        defaultValue={data.amount}
                                        {...register('amount', {
                                            required: {
                                                value: "required",
                                                message: "Amount is required"
                                            }
                                        })} type="number"

                                    />
                                    {errors.amount && <Form.Text
                                        className="text-muted validationText hasError">{errors.amount.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="admin_cut">
                                    <Form.Label>Admin Cut</Form.Label>
                                    <Form.Control
                                        placeholder="Admin Cut"
                                        defaultValue={data.admin_cut}
                                        {...register('admin_cut', {
                                            required: {
                                                value: "required",
                                                message: "Admin Cut is required"
                                            }
                                        })} type="number"

                                    />
                                    {errors.admin_cut && <Form.Text
                                        className="text-muted validationNumber hasError">{errors.admin_cut.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                    <div className="">
                        <Row>
                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="total_amount">
                                    <Form.Label>Total Amount</Form.Label>
                                    <Form.Control
                                        placeholder="Total Amount"
                                        defaultValue={data.total_amount}
                                        {...register('total_amount', {
                                            required: {
                                                value: "required",
                                                message: "Total Amount is required"
                                            }
                                        })} type="number"

                                    />
                                    {errors.total_amount && <Form.Text
                                        className="text-muted validationNumber hasError">{errors.total_amount.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="saved_amount">
                                    <Form.Label>Saved Amount</Form.Label>
                                    <Form.Control
                                        placeholder="Saved Amount"
                                        defaultValue={data.saved_amount}
                                        {...register('saved_amount', {
                                            required: {
                                                value: "required",
                                                message: "Saved Amount is required"
                                            }
                                        })} type="number"

                                    />
                                    {errors.saved_amount && <Form.Text
                                        className="text-muted validationNumber hasError">{errors.saved_amount.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                    <div className="">
                        <Row>
                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="stripe_cut">
                                    <Form.Label>Stripe Cut</Form.Label>
                                    <Form.Control
                                        placeholder="Stripe Cut"
                                        defaultValue={data.stripe_cut}
                                        {...register('stripe_cut', {
                                            required: {
                                                value: "required",
                                                message: "Stripe Cut is required"
                                            }
                                        })} type="number"

                                    />
                                    {errors.stripe_cut && <Form.Text
                                        className="text-muted validationNumber hasError">{errors.stripe_cut.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                {/*<Form.label>Upload Image</Form.label>*/}
                                <br/>
                                <Form.Group controlId="formFile"
                                            className="custom-file-upload mb-0">
                                    <Form.Label className={"toggleFileUpload mb-0"}>
                                        Image Upload
                                        <UploadIcon/>
                                    </Form.Label>
                                    <Form.Control type="file"
                                                  accept="image/png, image/jpeg, image/jpg, image/gif" {...register('media')} />

                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                    <div className="">
                        <Row>
                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="status">
                                    <Form.Label>Status</Form.Label>
                                    <Form.Select
                                        defaultValue={data.status}
                                        {...register('status', {
                                            required: {
                                                value: "required",
                                                message: "Status is required"
                                            },
                                        })}>
                                        <option>Choose Status</option>
                                        <option value='1'>Active</option>
                                        <option value='0'>Inactive</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>

                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                <br/>
                                <Form.Group className="mb-3" controlId="status">
                                    <Form.Check
                                        defaultValue={data.recommended}
                                        {...register('recommended', {
                                            required: {
                                                value: "required",
                                                message: "Recommended is required"
                                            },
                                        })}
                                        label="Recommended"
                                        type='checkbox'/>
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                    <div className={""}>
                        <Row>
                            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                <input type={"submit"} className={"btn btn-green-theme w-100 mt-3"} value={"Submit"}/>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Form>
        </div>
    )
}

export default Tokens
