import {apiUrl} from '../utils/constants';
import {GET, POST, PUT, DELETE} from './api.service.wrapper';

export const GetTokens = async (page = '', perPage = '', keyword = '') => {
    let params = {};

    if (page) {
        params['page'] = page;
    }

    if (perPage) {
        params['per-page'] = perPage;
    }

    if (keyword) {
        params['keyword'] = keyword;
    }

    return await GET(apiUrl.tokens, params);
}

export const GetTokenById = async (id) => {
    return  await GET(apiUrl.tokens + '/' + id);
}

export const CreateTokens = async (data) => {
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }
    return await POST(apiUrl.tokens, data, config);
}

export const UpdateToken = async (id, data) => {
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }
    return await PUT(apiUrl.tokens, id, data, config);
}

export const DeleteToken = async (id) => {
    return await DELETE(apiUrl.tokens, id);
}

export const DeleteAllTokens = async (ids) => {
    return await POST(apiUrl.delete_all_tokens, ids);
}
