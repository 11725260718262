import {apiUrl} from '../utils/constants';
import {GET, POST, PUT, DELETE} from './api.service.wrapper';

export const GetReport_Types = async (page = '', perPage = '', keyword = '') => {
    let params = {};

    if (page) {
        params['page'] = page;
    }

    if (perPage) {
        params['per-page'] = perPage;
    }

    if (keyword) {
        params['keyword'] = keyword;
    }

    return await GET(apiUrl.report_types, params);
}

export const GetReport_TypesById = async (id) => {
    return  await GET(apiUrl.report_types + '/' + id);
}

export const CreateReport_Types = async (data) => {
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }
    return await POST(apiUrl.report_types, data, config);
}

export const UpdateReport_Types = async (id, data) => {
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }
    return await PUT(apiUrl.report_types, id, data, config);
}

export const DeleteReport_Types = async (id) => {
    return await DELETE(apiUrl.report_types, id);
}

export const DeleteAllReport_Types = async (ids) => {
    return await POST(apiUrl.delete_all_faqs, ids);
}
