import {apiUrl} from '../utils/constants';
import {GET, POST, PUT, DELETE} from './api.service.wrapper';

export const GetReport = async (page = '', perPage = '', keyword = '') => {
    let params = {};

    if (page) {
        params['page'] = page;
    }

    if (perPage) {
        params['per-page'] = perPage;
    }

    if (keyword) {
        params['keyword'] = keyword;
    }

    return await GET(apiUrl.reports, params);
}

export const GetReportById = async (id) => {
    return  await GET(apiUrl.reports + '/' + id);
}

export const CreateReport = async (data) => {
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }
    return await POST(apiUrl.reports, data, config);
}

export const UpdateReport = async (id, data) => {
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }
    return await PUT(apiUrl.reports, id, data, config);
}

export const DeleteReport = async (id) => {
    return await DELETE(apiUrl.reports, id);
}

export const DeleteAllReports= async (ids) => {
    return await POST(apiUrl.delete_all_reports, ids);
}
