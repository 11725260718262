const roles = {
    ROLE_ADMIN: 1,
    ROLE_USER: 2,
    ROLE_PROFESSIONAL: 3,
}

const orderStatus = {
    ORDERED: 5,
    CONFIRMED: 10,
    CANCELLED: 15,
    PAID: 20,
    SHIPPED: 25,
    COMPLETED: 30,
    REVIEWED: 35,
    RETURNED: 40,
}

const notificationTypes = {
    CHAT: 5,
    PRODUCT: 10,
    ORDER: 20,
    STORE: 30,
    USER:40,
    ORDERED: 50,
    REVIEWED: 51,
    CANCELLED: 52,
    COMPLETED: 53,
    CONFIRMED_BY_CSR: 54,
    PAID: 55,
    RETURNED: 56,
    SHIPPED: 57,
    REVIEW:60
}

const orderStatusKey = {
    [orderStatus.ORDERED]: 'ORDERED',
    [orderStatus.CONFIRMED]: 'CONFIRMED',
    [orderStatus.CANCELLED]: 'CANCELLED',
    [orderStatus.PAID]: 'PAID',
    [orderStatus.SHIPPED]: 'SHIPPED',
    [orderStatus.COMPLETED]: 'COMPLETED',
    [orderStatus.REVIEWED]: 'REVIEWED',
    [orderStatus.RETURNED]: 'RETURNED',
}

const page = {
    PRIVACY_POLICY: 'privacy-policy',
    TERMS: 'terms-and-conditions'
}

const categoryStatus = {
    CUSTOMIZE: 10,
    IN_STOCK: 20,
    DESIGN: 30
}

const categoryStatusKey = {
    [categoryStatus.CUSTOMIZE]: 'CUSTOMIZE',
    [categoryStatus.IN_STOCK]: 'IN_STOCK',
    [categoryStatus.DESIGN]: 'DESIGN'
}

const collections = {
    CHATROOM: "chatRoom_live",
    USERS: "users_live",
    CHATLIST: "chatlist",
    GENERALCHATLIST: "general_chat_users_live",
    GENERALCHATROOM: "general_chatRoom_live",
}

const s3Credential = {
    /* Live Credentials*/
    /*bucketName: "imprint-media",
    dirName: "chat",
    region: "us-east-2",
    accessKeyId: "AKIAQHMBY74RRTTHPF6X",
    secretAccessKey: "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",
    s3EndPoint: "'https://imprint-media.s3.ap-south-1.amazonaws.com/",
    fileSize: '5000000'*/

    /* Local Credentials for Bucket */
    bucketName: "imprint-app",
    dirName: "chat",
    region: "ap-south-1",
    accessKeyId: "AKIAXCCCZ7TIGCSQPJIW",
    secretAccessKey: "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",
    s3EndPoint: "https://imprint-app.s3.ap-south-1.amazonaws.com",
    fileSize: '5000000'
}

const errorMessages = {
    fileSize: 'The file size is too large',
    fileSuccess: 'file uploaded successfully',
    fileError: 'Error in uploading file',
}

const contentType = {
    'image/jpeg': 'image',
    'image/jpg': 'image',
    'image/png': 'image',
    'image/gif': 'image',
    'application/pdf': 'doc',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'doc',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'doc',
    'audio/mpeg': 'audio',
    'video/mp4': 'video',
}

const chatMessage = {
    CONTENT: 0,
    CONTENT_SOFT_DELETE: 1,
    CONTENT_HARD_DELETE: 2,
}

const AdType = {
    IMAGE: "image",
    VIDEO: "video"
}

export const constants = {
    LOCAL_STORAGE_TOKEN: 'AUTH_ACCESS_TOKEN',
    LOCAL_STORAGE_USER: 'USER_DATA',
    BASE_URL: process.env.REACT_APP_BASE_URL,
    CSA_URL: process.env.REACT_APP_CSA_BASE_URL,
    ADMIN_URL: process.env.REACT_APP_ADMIN_BASE_URL,
    VENDOR_URL: process.env.REACT_APP_VENDOR_BASE_URL,
    ROLES: roles,
    ORDERSTATUS: orderStatus,
    ORDERSTATUSKEY: orderStatusKey,
    NOTIFICATIONTYPES: notificationTypes,
    LOCALE: 'en-OM',
    CURRENCY: 'OMR',
    PAGES: page,
    PERPAGE: 20,
    IMAGENOTFOUND: 'https://imprint.tekstaging.com/not-found.png',
    USERIMAGENOTFOUND: 'https://imprint.tekstaging.com/not-found-user.png',
    CATEGORYSTATUS: categoryStatus,
    CATEGORYSTATUSKEY: categoryStatusKey,
    COLLECTION: collections,
    S3CREDENTIAL: s3Credential,
    ERRORMESSAGES: errorMessages,
    CONTENTTYPE: contentType,
    CHATMESSAGE: chatMessage,
    ADTYPE: AdType,
};

export const apiUrl = {
    login: 'login',
    logout: 'logout',
    forgot_password: 'forgot-password',
    verify_otp: 'verify-otp',
    reset_password: 'reset-password',
    change_password: 'change-password',
    pages: 'pages',
    orders: 'orders',
    updateStatus: 'update-status',
    dashboard: 'dashboard-data',
    products: 'products',
    categories: 'categories',
    sub_categories: 'sub-categories',
    profile: 'me',
    update_profile: 'users',
    notifications: 'notifications',
    users: 'users',
    register: 'register',
    product_variants: 'product-variants',
    order_product_variants: 'order-product-variants',
    update_order_product_variants: 'update-order-product-variant',
    remove_attachment: 'remove-attachment',
    product_comparisons: 'product-comparisons',
    colors: 'colors',
    get_colors: 'get-colors',
    ads: 'ads',
    create_shipping: 'create-shipping',
    track_shipping: 'track-shipping',
    order_shipping: 'order-shippings',
    transactions: 'transactions',
    update_transaction_status: 'update-transaction-status',
    fetch_countries: 'fetch-countries',
    fetch_states: 'fetch-states',
    fetch_cities: 'fetch-cities',
    validate_address: 'check-address',
    chat_notification: 'chat-notification',
    reviews: 'reviews',
    faqs: 'faqs',
    report_types: 'report-types',
    reports: 'reports',
    professions: 'professions',
    tokens: 'tokens',
    avatars: 'avatars',
    offensive_words: 'offensive-words',
    charities: 'charities',
    chat_requests: 'chat-requests',
    dashboard_stats: 'dashboard-stats'
}

export const customStyles = {
    rows: {
        style: {
            minHeight: '50px',
        },
    },
    headCells: {
        style: {
            color: '#000',
            fontSize: '12px',
            fontWeight: '500',
            lineHeight: '19px',
            fontFamily: 'Poppins',
            padding: '5px',
            textTransform: 'uppercase'
        },
    },
    cells: {
        style: {
            padding: '5px',
            fontSize: '12px',
            fontWeight: '400',
            lineHeight: '19px',
            fontFamily: 'Poppins',
        },
    },
}
