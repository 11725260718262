import React from 'react'
import {NavLink} from "react-router-dom"
import {
    ManageUsersIcon,
    ManagePaymentsIcon,
    ReportsIcon,
    SettingsIcon,
    HomeIcon,
} from '../SvgIcons'

const Admin = () => {
  return <div className="d-flex flex-column flex-shrink-0 p-2 h-100 sideBar">
            <ul className="nav nav-pills sidebarNav flex-column mb-auto">
                <li className="nav-item">
                    <NavLink to="/dashboard" className="nav-link" activeClassName="active">
                        <HomeIcon/>
                        Dashboard
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to="/manage-users" className="nav-link" activeClassName="active">
                        <ManageUsersIcon/>
                        Users
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to="/professional-users" className="nav-link" activeClassName="active">
                        <ManageUsersIcon/>
                        Professionals
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to="/payments-management" className="nav-link" activeClassName="active">
                        <ManagePaymentsIcon/>
                        Payouts
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to="/reported-content" className="nav-link" activeClassName="active">
                        <ReportsIcon/>
                        Reports
                    </NavLink>
                </li>
                {/*<li className="nav-item">
                    <NavLink to={'/settings/'} className="nav-link" activeClassName="active">
                        <SettingsIcon/>
                        Settings
                    </NavLink>
                </li>*/}
                <li className="nav-item">
                    <NavLink to={'/page-list/'} className="nav-link" activeClassName="active">
                        <SettingsIcon/>
                        Pages
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to={'/faqs/'} className="nav-link" activeClassName="active">
                        <SettingsIcon/>
                        FAQ's
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to={'/charities/'} className="nav-link" activeClassName="active">
                        <SettingsIcon/>
                        Charities
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to={'/offensive-words/'} className="nav-link" activeClassName="active">
                        <SettingsIcon/>
                        Offensive Keywords
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to={'/professions/'} className="nav-link" activeClassName="active">
                        <SettingsIcon/>
                        Professions
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to={'/report-types/'} className="nav-link" activeClassName="active">
                        <SettingsIcon/>
                        Report Types
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to={'/list-tokens/'} className="nav-link" activeClassName="active">
                        <SettingsIcon/>
                        Tokens
                    </NavLink>
                </li>
            </ul>

        </div>;
};

export default Admin;
