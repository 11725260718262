import React, {useEffect, useLayoutEffect} from 'react'
import {Form, Label, Button} from 'react-bootstrap'
import logoIcon from '../../assets/images/logo-full.svg'
import {useForm} from "react-hook-form";
import { useHistory } from "react-router";
import {EmptyLocalStorage, VerifyOtpApi} from "../../services/auth/auth.service"
import {toast} from "react-toastify";

function VerifyCode({location}) {
    const history = useHistory();

    const {register, handleSubmit, watch, formState: {errors}} = useForm({
        mode: "onChange"
    });

    useLayoutEffect(() => {
        if(!location.state?.email){
            history.push('/');
        }
    }, [])

    const onSubmit = async data => {
        let verificationCode = data.verificationCode
        let email = location.state.email
        await VerifyOtpApi(verificationCode, email).then((data) => {
            if (data.status) {
                toast.success(data.message);
                history.push('/reset-password', {
                    verificationCode: verificationCode,
                    email: email
                })
            }
            else{
                toast.error(data.message);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })

    };

    return (
        <div className={"text-center authLayout"}>
            <Form className={"form-signin"} onSubmit={handleSubmit(onSubmit)}>
                <a href="/">
                    <img className="img-fluid mb-4" src={logoIcon} alt="Imprint Logo" title="Imprint Logo" style={{padding: "10px 25px"}}/>
                </a>
                <h1 className="h6 mb-3 font-weight-normal">Code Verification</h1>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Control
                        placeholder="Enter Code"
                        {...register('verificationCode', {
                            required: {
                                value: "required",
                                message: "Verification code is required"
                            },
                            minLength: {
                                value: 4,
                                message: "Min length is 4"
                            },
                            maxLength: {
                                value: 4,
                                message: "Max length is 4"
                            },
                        })}
                        type="text"
                    />
                    {errors.verificationCode && <span className={"validation-error pr-5"} role="alert">{errors.verificationCode.message}</span>}
                </Form.Group>


                <div className="d-grid gap-2">
                    <Button variant="primary" size="lg" type="submit" className={"btn-green-theme"}>
                        Verify Code
                    </Button>
                </div>
                <p className="mt-5 mb-3 text-muted">&copy; All rights reserved by  OurChat Inc.</p>
            </Form>
        </div>
    )
}

export default VerifyCode
