import React from 'react'
import {Row, Col, ToggleButtonGroup, Button, ToggleButton, Form} from 'react-bootstrap'

const Settings = () => {
    return(
        <div className={"settings"}>
            <h3 className="page-heading">Settings</h3>
            <hr/>
            <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <span className={"section-label"}>Section 1</span>
                    <div className={"box-bordered"}>
                        <div className={"settingBox mb-2"}>
                            <Row className={"justify-content-between"}>
                                <Col>
                                    <h4>Setting #1</h4>
                                    <p>Lorem ipsum dolor sit amet adipiscing consectetur do elit sit amet</p>
                                </Col>
                                <Col>
                                    <ToggleButtonGroup type="radio" name="options" defaultValue={1} className="mb-2">
                                        <ToggleButton id="tbg-check-1" value={1} >
                                            Option 1
                                        </ToggleButton>
                                        <ToggleButton id="tbg-check-2" value={2} >
                                            Option 2
                                        </ToggleButton>
                                        <ToggleButton id="tbg-check-3" value={3} >
                                            Option 3
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </Col>
                            </Row>
                        </div>
                        <div className={"settingBox mb-2"}>
                            <Row className={"justify-content-between"}>
                                <Col>
                                    <h4>Setting #2</h4>
                                    <p>Lorem ipsum dolor sit amet adipiscing consectetur do elit sit amet</p>
                                </Col>
                                <Col>
                                    <div className={"range-container"}>
                                        {/*<label htmlFor="customRange2" className="form-label">Example range</label>*/}
                                        <input type="range" className="form-range" min="0" max="100" id="customRange2" />
                                        <input type="text" id="rangePrimary" />
                                    </div>
                                </Col>
                            </Row>
                        </div>

                    </div>

                    <span className={"section-label"}>Section 2</span>
                    <div className={"box-bordered"}>
                        <div className={"settingBox mb-2"}>
                            <Row className={"justify-content-between align-items-center"}>
                                <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                                    <h4>Setting #3</h4>
                                    <p>Lorem ipsum dolor sit amet adipiscing consectetur do elit sit amet</p>
                                </Col>
                                <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <Form.Group className="mb-3" controlId="formEmail">
                                        <Form.Control
                                            type="text"
                                            placeholder="Value"
                                            readOnly
                                            maxLength={"255"}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </div>
                        <div className={"settingBox mb-2"}>
                            <Row className={"justify-content-between align-items-center"}>
                                <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                                    <h4>Setting #4</h4>
                                    <p>Lorem ipsum dolor sit amet adipiscing consectetur do elit sit amet</p>
                                </Col>
                                <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <Form.Group className="mb-3" controlId="formEmail">
                                        <Form.Control
                                            type="text"
                                            placeholder="Value"
                                            readOnly
                                            maxLength={"255"}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </div>
                    </div>

                </Col>
            </Row>
        </div>
    )
}
export default Settings