import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import {
  Button,
  Col,
  Dropdown,
  DropdownButton,
  Form,
  FormControl,
  InputGroup,
  Row,
} from "react-bootstrap";
import {
  AddIconOutline,
  FilterIcon,
  MenuDots,
  SearchIcon,
  UploadIcon,
} from "../../components/SvgIcons";
import MaterialTable from "material-table";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { constants, customStyles } from "../../utils/constants";
import moment from "moment";
import {
  CreateUser,
  DeleteUser,
  GetUsers,
  UpdateUser,
} from "../../services/users.service";
import DataTable from "react-data-table-component";
import BarLoader from "react-spinners/BarLoader";
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import ThemeModal from "../../components/ThemeModal";
import { useForm } from "react-hook-form";
import {CreateAvatar, DeleteAvatar, GetAvatars} from "../../services/avatar.service";
import {CreateCharities} from "../../services/charity.service";

function Avatars() {
  let history = useHistory();
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(constants.PERPAGE);
  const [rows, setRows] = useState();
  const [keyword, setKeyword] = useState("");
  const [newAvatarShow, setNewAvatarShow] = useState(false);

      const [file, setFileData] = useState(false);
    const [defaultImage, setDefaultImage] = useState(false);
    const [disableButton, setDisableButton] = useState(false);

  const {register, handleSubmit, reset, setValue, formState: {errors}} = useForm({
        mode: "onChange"
    });


    useEffect(async () => {
        await getAvatars();
    }, [keyword, page, perPage])

    const getAvatars = async () => {
        setLoading(true)
        await GetAvatars().then((result) => {
            if (result.status) {
                if (result.data.data) {
                    const rowData = [];
                    setPage(result.data.meta.current_page)
                    setTotalRows(result.data.meta.total);
                    result.data.data.map((dt, index) => {
                        rowData.push({
                            id: dt.id,
                            index: ++index,
                            avatar: dt.image,
                            name: dt.name
                        });
                    })
                    setRows(rowData);
                    setLoading(false)
                }
            } else {
                setLoading(false)
                return toast.error(result.message);
            }
        }).catch((error) => {
            setLoading(false)
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })
    }

  const onAvatarDelete = (id) => {
    swal({
      title: "Are you sure?",
      text: "Are you sure you want to delete this Avatar?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willShip) => {
      //Delete Product API
      if (willShip) {
        await DeleteAvatar(id)
          .then((result) => {
            if (result.status) {
              swal(result.message, {
                icon: "success",
              });
              getAvatars()
            } else {
              toast.error(result.message);
            }
          })
          .catch((error) => {
            if (error.response.status == 401) {
              EmptyLocalStorage();
              history.push("/");
            } else {
              return toast.error(error.response.data.message);
            }
          });
      }
    });
  };

  const onEdit = (data) => {
    history.push("/manage-users/edit-user/" + data.id, {
      data: data,
    });
  };
  const onView = (data) => {
    history.push("/profile-details/" + data.id, {
      data: data,
    });
  };

  const userActions = (data) => {
      console.log(data, 'Ref Data')
    return (
      <DropdownButton
        align="end"
        className={"actionDropdown"}
        id="dropdown-item-button"
        title={<MenuDots />}
      >
        <Dropdown.Item as="button" onClick={onAvatarDelete(data.id)}>
          Delete
        </Dropdown.Item>
        <Dropdown.Item as="button">
          Deactivate Avatar
        </Dropdown.Item>
      </DropdownButton>
    );
  };

  const columns = [
    {
      id: "image",
      name: "Avatar",
      sortable: false,
      selector: (rowData) => <img src={rowData.avatar} className="img-fluid img-thumbnail" alt="" width={50} height={50}/>,
    },
    {
      id: "avatarName",
      name: "Avatar Name",
      selector: (rowData) => rowData.name,
    },
    {
      id: "action",
      name: "Action",
      cell: (rowData) => userActions(rowData),
    },
  ];
  const data = [
    {
      id: 1,
      image: "https://i.ibb.co/BKdVnvS/avatar1-lg.png",
      avatar_name: "Corey Morgan",
    },
    {
      id: 2,
      image: "https://i.ibb.co/6JJ5SZc/avatar5-lg.png",
      avatar_name: "Corey Morgan",
    },
    {
      id: 3,
      image: "https://i.ibb.co/6YbGXYp/avatar2-lg.png",
      avatar_name: "Corey Morgan",
    },
    {
      id: 4,
      image: "https://i.ibb.co/4KRy79c/avatar3-lg.png",
      avatar_name: "Corey Morgan",
    },
    {
      id: 5,
      image: "https://i.ibb.co/2qCHQFs/avatar4-lg.png",
      avatar_name: "Corey Morgan",
    },
  ];

    const onModalSubmit = async (data) => {
        console.log(data, 'Data')
        let formData = new FormData();
        formData.append('name', data.name);
        formData.append('image', data.media);

        await CreateAvatar(formData).then((data) => {
            if (data.status) {
                toast.success(data.message);
                getAvatars();
                reset();
            } else {
                toast.error(data.message);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })
    };

  const AddNewAvatarForm = () => {
    return(
      <div>
        <div className={"modalBodyContent"} onSubmit={handleSubmit(onModalSubmit)}>
                <Form>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Form.Group className="mb-3" controlId="name">
                                <Form.Label>Question<small style={{color: "red"}}>*</small></Form.Label>
                                <Form.Control
                                    placeholder="Type Name"
                                    autocomplete="off"
                                    {...register('name', {
                                        onChange: (e) => {
                                            setValue('name', e.target.value)
                                        },
                                        required: {
                                            value: "required",
                                            message: "Name is required"
                                        },
                                        maxLength: {
                                            value: 255,
                                            message: "Max Length is 255"
                                        }
                                    })} type="text"
                                />
                                {errors.name && <Form.Text
                                    className="text-muted validationText hasError">{errors.name.message}</Form.Text>}
                            </Form.Group>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6} className={"mb-3"}>
                            <img onError={({currentTarget}) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = constants.IMAGENOTFOUND;
                            }} src={"https://i.ibb.co/4KRy79c/avatar3-lg.png"} className={"img-fluid img-thumbnail"} width={100} height={100}/>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Form.Group controlId="formFile"
                                                className="custom-file-upload mb-0">
                              <Form.Label className={"toggleFileUpload mb-0"}>
                                  <UploadIcon/>
                                  Upload Image
                              </Form.Label>
                              <Form.Control type="file"
                                            accept="image/png, image/jpeg, image/jpg, image/gif" {...register('media')} />

                          </Form.Group>
                        </Col>

                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <input type={"submit"} disabled={disableButton} className={"btn btn-green-theme py-2 px-12 mt-3"}
                                   value={"Submit"}/>
                        </Col>
                    </Row>
                </Form>
            </div>
      </div>
    )
  }

  return (
    <div className={"Avatar"}>
      <h1 className="page-heading">Avatars</h1>
      <hr />
      <div className={"customOrderFilters"}>
        <Row>
          <Col xs={12} sm={12} md={4} lg={4} xl={4}>
            <Button className={"btn btn-green-theme h40 w-100"} onClick={() => setNewAvatarShow(true)}>
                <AddIconOutline/> Add New Avatar
            </Button>
          </Col>
          <Col xs={12} sm={12} md={{span:5, offset: 3}} lg={{span:5, offset: 3}} xl={{span: 5, offset: 3}}>
            <InputGroup className="mb-3 search-group">
              <InputGroup.Text id="basic-addon1">
                <SearchIcon />
              </InputGroup.Text>
              <FormControl
                placeholder="Search"
                aria-label="Search"
                aria-describedby="Search"
                maxLength={"255"}
                onChange={(e) => {
                  e.target.value.length > 2
                    ? setKeyword(e.target.value)
                    : setKeyword(null);
                }}
              />
            </InputGroup>
          </Col>
        </Row>
      </div>
      <div className="theme-dataGrid products-dataGrid">
        <DataTable
          columns={columns}
          // data={rows}
          data={rows}
          progressPending={loading}
          customStyles={customStyles}
          selectableRows={false}
          striped
          highlightOnHover
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          paginationPerPage={perPage}
          onChangePage={(page) => {
            setPage(page);
          }}
          onChangeRowsPerPage={(currentRowsPerPage) => {
            setPerPage(currentRowsPerPage);
          }}
          progressComponent={
            <BarLoader
              color={"#12726C"}
              loading={loading}
              css={"marginTop: 10px"}
              height={"4"}
              width={"100%"}
            />
          }
        />
        <ThemeModal  title={"Add New Avatar"} content={<AddNewAvatarForm/>} size={"md"} show={newAvatarShow}
                        onHide={() => setNewAvatarShow(false)}/>
      </div>
    </div>
  );
}

export default Avatars;
