import {constants, apiUrl} from '../utils/constants';
import {GET, POST, PUT, DELETE} from './api.service.wrapper';
import {GetAuthUser, GetUserRole} from "../services/auth/auth.service"
import {createUser} from "./firebase.service";

const user = GetAuthUser();
let role = GetUserRole();

export const GetProfile = async () => {
    let response = await GET(apiUrl.profile);
    return response;
}

export const UpdateProfile = async (id, data) => {
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }
    const response = await PUT(apiUrl.update_profile, id, data, config);
    return response;
}

export const GetUsers = async (keyword = '', role_id= '', page = '', perPage = '') => {
    let params = {};

    if(page){
        params['page'] = page;
    }

    if(perPage){
        params['per-page'] = perPage;
    }

    if(role_id){
        params['role_id'] = role_id;
    }

    if(keyword){
        params['keyword'] = keyword;
    }

    const response = await GET(apiUrl.users, params);
    return response;
}

export const GetUserById = async (id) => {
    let response = '';
    response = await GET(apiUrl.users + '/' + id);
    return response;
}

export const CreateUser = async (data) => {
    const response = await POST(apiUrl.register, data);
    return response;
}

export const UpdateUser = async (id, data) => {
    const response = await PUT(apiUrl.users, id, data);
    return response;
}

export const DeleteUser = async (id) => {
    const response = await DELETE(apiUrl.users, id);
    return response;
}

export const ChatNotification = async (data) => {
    const response = await POST(apiUrl.chat_notification, data);
    return response;
}

export const DashboardStats = async (interval) => {
    let params = {};

    if(interval){
        params['interval'] = interval;
    }

    const response = await GET(apiUrl.dashboard_stats, params)
    return response;
}
