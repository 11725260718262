import React, {useState, useEffect, useContext, useLayoutEffect} from 'react'
import {Navbar, NavDropdown, Nav, Container, Button} from 'react-bootstrap'
import {FaRegBell, FaRegUserCircle} from "react-icons/fa";
import {BiMessageSquareDetail} from "react-icons/bi";
import NotificationIcon from './../assets/images/notification-icon.png'
import Logo from './../assets/images/logo-full.svg'
import {useHistory} from "react-router";
import {Logout, EmptyLocalStorage, GetAuthUser, GetUserRole} from '../services/auth/auth.service'
import {toast} from "react-toastify";
import {GetNotifications} from "../services/notifications.service";
import {constants} from "../utils/constants";
import UserContext from "../hooks/UserContext";
import {AiFillNotification} from "react-icons/ai";
import {NavLink} from "react-router-dom";

function Header(props) {
    const [notificationStatus, setNotificationStatus] = useState(false);
    const [chatnotificationStatus, setchatNotificationStatus] = useState(false);
    const [data, setData] = useState();
    const [showChat, setShowChat] = useState(false);
    const history = useHistory();
    //const {user} = useContext(UserContext);
    const [user, setUser] = useState('')

    useEffect(() => {
        if (GetUserRole() !== constants.ROLES.ROLE_ADMIN) {
            setShowChat(true)
        }
        // getNotifications();
    }, [])

    /*useLayoutEffect(() => {
        setUser(GetAuthUser()?.full_name)
    }, [])*/

    const getNotifications = async () => {
        await GetNotifications().then((result) => {
            if (result.status) {
                if (result.data.data) {
                    const rowData = [];
                    result.data.data.map((dt) => {
                        rowData.push({
                            id: dt.id,
                            notifiable_id: dt.notifiable_id,
                            title: dt.title,
                            message: dt.message,
                            ref_id: dt.ref_id,
                            type: dt.type,
                            read_at: dt.read_at,
                            extra: dt.extra,
                            created_at: dt.created_at,
                            updated_at: dt.updated_at,
                            deleted_at: dt.deleted_at,
                            image: dt.image,
                            created_ago: dt.created_ago,
                        });
                    })

                    setData(rowData);
                }
            } else {
                return toast.error(result.message);
            }
        }).catch((error) => {
            console.log(error);
            return toast.error(error.response.data.message);
        })
    }

    async function handleLogout() {
        await Logout().then(async (data) => {
            if (data.status) {
                toast.success(data.message);
                await EmptyLocalStorage();
                history.push('/');
            } else {
                toast.error(data.message);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                console.log(error);
                return toast.error(error.response.data.message);
            }
        })
    }

    const checkNotifications = () => {
        const notificationCount = 0;
        /*
        *
        *  checking the notification count and set to true
        *
        */
        if (notificationCount > 1) {
            setNotificationStatus(!notificationStatus);
        }

    }
    const checkChatNotifications = () => {
        const chatNotificationCount = 0;
        /*
        *
        *  checking the notification count and set to true
        *
        */
        if (chatNotificationCount > 1) {
            setchatNotificationStatus(!chatnotificationStatus);
        }
    }

    const navDropdownTitle = (<>
        <FaRegUserCircle/> {user}
    </>);

    return (
        <div>
            <Navbar bg="light" expand="lg" className={"imprint-header shadow"}>
                <Container fluid>
                    <Navbar.Brand href="/dashboard">
                        <img src={Logo} alt="OurChat" title={"OurChat"} className={"img-fluid"}/>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    {/*<Navbar.Collapse id="basic-navbar-nav">*/}
                    {/*    <Nav className="mx-auto">*/}
                    {/*        <NavLink to={"/dashboard"} className={"nav-link"}>*/}
                    {/*            Dashboard*/}
                    {/*        </NavLink>*/}
                    {/*        <NavLink to={"/manage-users"} className={"nav-link"}>*/}
                    {/*            Users*/}
                    {/*        </NavLink>*/}
                    {/*        <NavLink to={"/payments-management"} className={"nav-link"}>*/}
                    {/*            Payouts*/}
                    {/*        </NavLink>*/}
                    {/*        <NavLink to={"/reported-content"} className={"nav-link"}>*/}
                    {/*            Reported Content*/}
                    {/*        </NavLink>*/}
                    {/*        <NavLink to={"/analytics"} className={"nav-link"}>*/}
                    {/*            Analytics*/}
                    {/*        </NavLink>*/}
                    {/*        <NavLink to={"/settings"} className={"nav-link"}>*/}
                    {/*            Settings*/}
                    {/*        </NavLink>*/}

                    {/*    </Nav>*/}
                    {/*</Navbar.Collapse>*/}
                    <Nav className="ms-auto d-none d-lg-flex">


                        <NavDropdown align="end" title={navDropdownTitle} id="basic-nav-dropdown"
                                     className={"profileDropdown"}>
                            {/*<NavDropdown.Item href="/profile">Profile</NavDropdown.Item>*/}
                            <NavDropdown.Item href="/change-password">Change Password</NavDropdown.Item>
                            <NavDropdown.Item onClick={handleLogout} href="javascript:;">Logout</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Container>
            </Navbar>
        </div>
    )
}

export default Header
