import React, {useEffect, useState} from 'react'
import {Row, Col, Button, Dropdown} from 'react-bootstrap';
import {FaChartPie} from "react-icons/fa";
import DashboardStat from '../../../components/DashboardStat'
import {GetDashboard} from "../../../services/admin/dashboard.service"
import {toast} from "react-toastify";
import {NavLink} from "react-router-dom"
import {constants} from "../../../utils/constants";
import {EmptyLocalStorage} from "../../../services/auth/auth.service";
import {useHistory} from 'react-router-dom';
import Profile01 from '../../../assets/images/profile.jpg'
import {ChevronRight} from "../../../components/SvgIcons";
import {CreateUser, DashboardStats, DeleteUser, GetUsers, UpdateUser} from "../../../services/users.service";
import {GetTransactions} from "../../../services/transaction.service";

function Admin({RoleId}) {
    const history = useHistory();
    const [stats, setStats] = useState([]);
    const [payout, setPayout] = useState([])
    const [rows, setRows] = useState([]);
    const [interval, setInterval] = useState('current_month');
    const [intervalValue, setIntervalValue] = useState('Current Month');
    const [loading, setLoading] = useState(0);

    useEffect( () => {
        getUsers();
        getTransaction();
    }, [])

    useEffect(() => {
        dashboardStats()
    }, [interval]);

    const dashboardStats = async () =>{
        await DashboardStats(interval).then((data) => {
            if (data.status) {
                setStats(data.data);
                console.log(data.data);
            } else {
                toast.error(data.message);
            }
        }).catch((error) => {
            // setLoading(false)
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })
    }

    const getUsers = async () => {
        setLoading(true)
        await GetUsers().then((result) => {

            if (result.status) {
                if (result.data.data) {
                    const rowData = [];
                    // setPage(result.data.meta.current_page)
                    // setTotalRows(result.data.meta.total);
                    result.data.data.map((dt, index) => {
                         rowData.push({
                            id: dt.id,
                            index: ++index,
                            full_name: dt.name,
                            roles: dt.roles[0] ? dt.roles[0].name : '-',
                            profession: dt.profession!=null ? dt.profession.name : '',
                            image: dt.image || '-'
                            // created_at: moment(dt.created_at).fromNow(),
                        });
                    })
                    setRows(rowData);
                    setLoading(false)
                }
            } else {
                // setLoading(false)
                return toast.error(result.message);
            }
        }).catch((error) => {
            // setLoading(false)
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })
    }

    const getTransaction = async () => {
        setLoading(true)
        await GetTransactions().then((result) => {
            if (result.status) {
                if (result.data.data) {
                    const rowData = [];
                    // setPage(result.data.meta.current_page)
                    // setTotalRows(result.data.meta.total);
                    result.data.data.map((dt, index) => {
                        if(dt.type == 20) rowData.push({
                            id: dt.id,
                            index: ++index,
                            full_name: dt.user.name,
                            tokens: dt.tokens*0.01,
                            created_at: dt.created_at.split('T')[0],
                            status: dt.status == "succeeded" ? 'PAID' : 'UNPAID',
                            image: dt.user.image

                        });
                    })
                    setPayout(rowData);
                    setLoading(false)
                }
            } else {
                // setLoading(false)
                return toast.error(result.message);
            }
        }).catch((error) => {
            // setLoading(false)
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })
    }

    return (
        <div>
            <Row className={"mb-4"}>
                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                    <h1>Dashboard</h1>
                </Col>
                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                    <div className={"timelineControls"}>
                        <p>Interval</p>
                        <Dropdown className={"intervalDropdown"}>
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                {intervalValue}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item onClick={(e) => {
                                    setInterval('current_month')
                                    setIntervalValue('Current Month')
                                }} href="#/action-1">Current Month</Dropdown.Item>
                                <Dropdown.Item onClick={(e) => {
                                    setInterval('week')
                                    setIntervalValue('This Week')
                                }} href="#/action-1">This Week</Dropdown.Item>
                                <Dropdown.Item onClick={(e) => {
                                    setInterval('today')
                                    setIntervalValue('Today')
                                }} href="#/action-2">Today</Dropdown.Item>
                                <Dropdown.Item onClick={(e) => {
                                    setInterval('last_month')
                                    setIntervalValue('Last Month')
                                }} href="#/action-3">Last Month</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>

                </Col>
            </Row>
            <Row className={"mb-4"}>
                <Col xs={12} sm={12} md={6} lg={3} xl={3}>
                    <NavLink to={"#"} className={"linkStats"}>
                        <DashboardStat title={"Messages requests"} countMain={stats?.message_request || 0}/>
                    </NavLink>
                </Col>
                <Col xs={12} sm={12} md={6} lg={3} xl={3}>
                    <NavLink to={"#"} className={"linkStats"}>
                        <DashboardStat title={"Purchases"} countMain={stats?.payouts || "0"}/>
                    </NavLink>
                </Col>
                <Col xs={12} sm={12} md={6} lg={3} xl={3}>
                    <NavLink to={"#"} className={"linkStats"}>
                        <DashboardStat title={"Payouts"} countMain={stats?.purchases || "$0"}/>
                    </NavLink>
                </Col>
                <Col xs={12} sm={12} md={6} lg={3} xl={3}>
                    <NavLink to={"#"} className={"linkStats"}>
                        <DashboardStat title={"Accounts"} userCount={stats?.users || "0"} proCount={stats?.professionals || "0"}/>
                    </NavLink>
                </Col>
            </Row>
            <Row className={""}>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <div className={"box-outline"}>
                        <div className={"boxHeader"}>
                            <h6>Latest users</h6>
                            <NavLink to={"/manage-users"} className={"link"}>View All</NavLink>
                        </div>
                        <div className={"chatTilesContainer"}>
                            <ul className={"tilesList"}>
                                {rows.map((data,index)=>{
                                    return(
                                    <li className={""} key={index}>
                                    <div className={"tileComponent"}>
                                    <div className={"profileImgContainer"}>
                                    <div className={"imgTile"}>
                                    <img className={"img-fluid"} src={data.image!='-' ? data.image : Profile01} alt={"Logan"} title={"Logan"} />
                                    </div>
                                    </div>
                                    <div className={"profileDetails"}>
                                    <div className={"profileDetailsHeader"}>
                                    <div className={"profileName"}>{data.full_name}</div>
                                    </div>
                                    <span className={"ageTag"}>
                                    {data.roles}
                                    </span>
                                    <p>{data.profession}</p>

                                    </div>
                                    <div className={"chevContainer"}>
                                    <ChevronRight />
                                    </div>
                                    </div>
                                    </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <div className={"box-outline"}>
                        <div className={"boxHeader"}>
                            <h6>Latest payouts requests</h6>
                            <NavLink to={"/manage-users"} className={"link"}>View All</NavLink>
                        </div>
                        <div className={"chatTilesContainer"}>
                            <ul className={"tilesList"}>
                                {payout.map((data, index)=>{
                                    return(
                                    <li className={""} key={index}>
                                    <div className={"tileComponent payouts"}>
                                        <div className={"profileImgContainer"}>
                                            <div className={"imgTile"}>
                                                <img className={"img-fluid"} src={data.image!=null ? data.image : Profile01} alt={"Logan"}
                                                     title={"Logan"}/>
                                            </div>
                                        </div>
                                        <div className={"profileDetails"}>
                                            <div className={"profileDetailsHeader"}>
                                                <div className={"profileName"}>{data.full_name}</div>
                                            </div>
                                            <p>${data.tokens} • {data.created_at}</p>
                                        </div>
                                        <div className={"chevContainer"}>
                                            <span className={"statusTag success"}>
                                                {data.status}
                                            </span>
                                            <ChevronRight/>
                                        </div>
                                    </div>
                                </li>)})}
                            </ul>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default Admin
