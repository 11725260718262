import React, {useState, useEffect} from 'react'
import {Form, Row, Col} from "react-bootstrap";
import {useForm} from "react-hook-form";
// import {Editor} from "react-draft-wysiwyg";
import {toast} from "react-toastify";
import {useHistory} from 'react-router-dom';
import moment from "moment";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import {UploadIcon} from "../../components/SvgIcons";
import {GetTokenById, UpdateToken} from "../../services/token.service";
import {constants} from "../../utils/constants";
import Profile from "../../assets/images/profile.jpg"


function ViewTokens(props) {
    let history = useHistory();
    const [data, setData] = useState([]);

    const editorConfiguration = {
        toolbar: [ 'bold', 'italic', 'link', 'undo', 'redo', 'numberedList', 'bulletedList' ]
    };

    const getTokensById = async (id) => {
        await GetTokenById(id).then((result) => {
            console.log(result)
            if (result.status) {
                if (result.data) {
                    setData({
                        id: result.data.id,
                        name: result.data.name,
                        tokens: result.data.tokens,
                        amount: result.data.amount,
                        admin_cut: result.data.admin_cut,
                        total_amount: result.data.total_amount,
                        saved_amount: result.data.saved_amount,
                        stripe_cut: result.data.stripe_cut,
                        status: result.data.status,
                        recommended: result.data.recommended==1?true: false
                    });
                }
            } else {
                return toast.error(result.message);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })
    }

    useEffect(async () => {
        let id = props.match.params.id;
        await getTokensById(id);
        if (data) {
            reset({
                'title': data.title,
                'content': data.content,
            });
        }
    }, []);

    const {register, handleSubmit, reset, setValue, formState: {errors}} = useForm({
        mode: "onChange"
    });

    const onSubmit = async (data) => {

        history.push('/list-tokens');

    };

    return (
        <div className={"ViewToken"}>
            <h3 className="page-heading">View Token</h3>
            <hr/>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <div className={"PageEditForm mt-3"}>
                    <div className="">
                        <Row>
                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="title">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        placeholder="Name"
                                        defaultValue={data.name}
                                        {...register('name', {
                                            required: {
                                                value: "required",
                                                message: "Name is required"
                                            },
                                            minLength: {
                                                value: 5,
                                                message: "max length is 5"
                                            },
                                            maxLength: {
                                                value: 255,
                                                message: "max length is 255"
                                            },
                                        })} type="text"

                                    />
                                    {errors.name && <Form.Text
                                        className="text-muted validationText hasError">{errors.name.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="tokens">
                                    <Form.Label>Tokens</Form.Label>
                                    <Form.Control
                                        placeholder="Tokens"
                                        defaultValue={data.tokens}
                                        {...register('tokens', {
                                            required: {
                                                value: "required",
                                                message: "Tokens is required"
                                            },
                                        })} type="number"

                                    />
                                    {errors.tokens && <Form.Text
                                        className="text-muted validationNumber hasError">{errors.tokens.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                    <div className="">
                        <Row>
                            <Col xs={12} sm={12} md={6} lg={12} xl={6}>
                                <Form.Group className="mb-3" controlId="amount">
                                    <Form.Label>Amount</Form.Label>
                                    <Form.Control
                                        placeholder="Amount"
                                        defaultValue={data.amount}
                                        {...register('amount', {
                                            required: {
                                                value: "required",
                                                message: "Amount is required"
                                            }
                                        })} type="number"

                                    />
                                    {errors.amount && <Form.Text
                                        className="text-muted validationText hasError">{errors.amount.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="admin_cut">
                                    <Form.Label>Admin Cut</Form.Label>
                                    <Form.Control
                                        placeholder="Admin Cut"
                                        defaultValue={data.admin_cut}
                                        {...register('admin_cut', {
                                            required: {
                                                value: "required",
                                                message: "Admin Cut is required"
                                            }
                                        })} type="number"

                                    />
                                    {errors.admin_cut && <Form.Text
                                        className="text-muted validationNumber hasError">{errors.admin_cut.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                    <div className="">
                        <Row>
                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="total_amount">
                                    <Form.Label>Total Amount</Form.Label>
                                    <Form.Control
                                        placeholder="Total Amount"
                                        defaultValue={data.total_amount}
                                        {...register('total_amount', {
                                            required: {
                                                value: "required",
                                                message: "Total Amount is required"
                                            }
                                        })} type="number"

                                    />
                                    {errors.total_amount && <Form.Text
                                        className="text-muted validationNumber hasError">{errors.total_amount.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="saved_amount">
                                    <Form.Label>Saved Amount</Form.Label>
                                    <Form.Control
                                        placeholder="Token"
                                        defaultValue={data.saved_amount}
                                        {...register('saved_amount', {
                                            required: {
                                                value: "required",
                                                message: "Saved Amount is required"
                                            }
                                        })} type="number"

                                    />
                                    {errors.saved_amount && <Form.Text
                                        className="text-muted validationNumber hasError">{errors.saved_amount.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                    <div className="">
                        <Row>
                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="stripe_cut">
                                    <Form.Label>Stripe Cut</Form.Label>
                                    <Form.Control
                                        placeholder="Token"
                                        defaultValue={data.stripe_cut}
                                        {...register('stripe_cut', {
                                            required: {
                                                value: "required",
                                                message: "Stripe Cut is required"
                                            }
                                        })} type="number"

                                    />
                                    {errors.stripe_cut && <Form.Text
                                        className="text-muted validationNumber hasError">{errors.stripe_cut.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <img onError={({currentTarget}) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = constants.IMAGENOTFOUND;
                                    }} src={data.image==null ? Profile : data.image} className={"img-fluid img-thumbnail"} width={100} height={100}/>
                            </Col>
                        </Row>
                    </div>
                    <div className="">
                        <Row>
                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="status">
                                    <Form.Label>Status</Form.Label>
                                    <Form.Select
                                        {...register('status', {
                                            required: {
                                                value: "required",
                                                message: "Status is required"
                                            },
                                        })} type="number">
                                        <option>{data.status==10 ? 'Active' : 'Inactive'}</option>
                                        <option value='1'>Active</option>
                                        <option value='0'>Inactive</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>

                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                <br/>
                                <Form.Group className="mb-3" controlId="status">
                                    <Form.Label>Recommended</Form.Label>
                                    <Form.Check
                                        defaultValue={data.recommended}
                                        {...register('recommended', {
                                        })}
                                        type='checkbox'/>
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                    <div className={""}>
                        <Row>
                            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                <input type={"submit"} className={"btn btn-green-theme w-100 mt-3"} value={"Submit"}/>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Form>
        </div>
    )
}

export default ViewTokens
